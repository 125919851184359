import styles from './BurgerMenu.module.css';
import {BurgerMenuButton} from "../BurgerMenuButton";
import {NavLink} from "react-router-dom";
import {LangDropdown} from "../LangDropdown";
import {useTranslation} from 'react-i18next';

export const BurgerMenu = ({handleClick}) => {
  const {t} = useTranslation();
  const handleMenu = () => {
    handleClick()
  }

  return (
    <div className={styles.root}>
      <BurgerMenuButton handleClick={handleMenu} className={styles.button}/>
      <div className={styles.container}>
        {
          (t('menuItems') || []).map(item => (
            <NavLink to={item.path} key={item.name}>{item.name}</NavLink>
          ))
        }
        <LangDropdown className={styles.lang}/>
      </div>
    </div>
  )
}
