import styles from './Services.module.css';
import {Title, VideoPopup} from "../../components";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import clsx from "clsx";

export const Services = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [videoData, setVideoData] = useState(null);
  const {t} = useTranslation();
  const slides = t("services.slides");

  if (!slides) {
    return null;
  }

  const length = slides.length;

  const [first, second] = [slides.slice(0, Math.round(length / 2)), slides.slice(Math.round(length / 2), length)]

  const handleVideo = (data) => {
    setIsOpen(true);
    setVideoData(data)
  }

  return (
    <div className={styles.root}>
      {
        isOpen &&
        <VideoPopup data={videoData} handleClick={() => setIsOpen(false)}/>
      }
      <Title className={styles.title} title={t("services.title")} hasRectangle/>
      <div className={styles.container}>
        <div className={styles.side}>
          {
            first.map((item, index) => (
              <div key={index} className={clsx(styles.chip, "kfn_anim", "k-fadeUp")} onClick={() => handleVideo(item)}>
                <div className={styles.video}>
                  <img src={require(`../../assets/images/services/${item.image}`)} alt=""/>
                  <svg width="30" height="38" viewBox="0 0 30 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_b_492_254)">
                      <path d="M0 0V38L30 19L0 0Z" fill="#FFFFFF" fillOpacity="1"/>
                    </g>
                    <defs>
                      <filter id="filter0_b_492_254" x="-4" y="-4" width="38" height="46" filterUnits="userSpaceOnUse"
                              colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2"/>
                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_492_254"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_492_254" result="shape"/>
                      </filter>
                    </defs>
                  </svg>
                </div>
                <div className={styles.chipTitle}>{item.title}</div>
                <div className={styles.text}>{item.text}</div>
              </div>
            ))
          }
        </div>
        <div className={styles.side}>
          {
            second.map((item, index) => (
              <div key={index} className={clsx(styles.chip, "kfn_anim", "k-fadeUp")} onClick={() => handleVideo(item)}>
                <div className={styles.video}>
                  <img src={require(`../../assets/images/services/${item.image}`)} alt=""/>
                  <svg width="30" height="38" viewBox="0 0 30 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_b_492_254)">
                      <path d="M0 0V38L30 19L0 0Z" fill="#FFFFFF" fillOpacity="1"/>
                    </g>
                    <defs>
                      <filter id="filter0_b_492_254" x="-4" y="-4" width="38" height="46" filterUnits="userSpaceOnUse"
                              colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2"/>
                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_492_254"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_492_254" result="shape"/>
                      </filter>
                    </defs>
                  </svg>
                </div>
                <div className={styles.chipTitle}>{item.title}</div>
                <div className={styles.text}>{item.text}</div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}
