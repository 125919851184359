import React from 'react';
import {Rectangle} from "../Rectangle";
import {Link} from "react-router-dom";

import styles from './Breadcrumbs.module.css'
import clsx from "clsx";

export const Breadcrumbs = ({links, className}) => {
  return (
    <div className={clsx(styles.root, className, "kfn_anim", "k-fadeIn")}>
      <Rectangle className={styles.rectangle}/>
      <div className={styles.content}>
        {links.map(({title, to}, index) => (<span key={index} className={styles.link}>
                    <Link className={styles.text} to={to}>{title}</Link>
                    <svg className={styles.icon} width="8" height="13"
                         viewBox="0 0 8 13"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg">
<path d="M0 11.08L4.94467 6.5L0 1.91L1.52227 0.5L8 6.5L1.52227 12.5L0 11.08Z" fill="#FCFCFC"/>
</svg>
</span>))}
      </div>
    </div>
  );
};

