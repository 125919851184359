import {Gallery, MainSlider, ProductsSlider, Services, TrustedBy, VideoPopup} from "../components";
import {Contact} from "../components/Contact";
import {ProductsTab} from "../components/ProductsTab";
import {useState} from "react";

export const Home = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [videoData, setVideoData] = useState(null);
  const handleVideo = (data) => {
    setIsOpen(true);
    setVideoData(data)
  }

  return (
    <>
      <MainSlider/>
      <TrustedBy/>
      <Services handleClick={(data) => handleVideo(data)}/>
      <Gallery/>
      <ProductsSlider/>
      <ProductsTab/>
      <Contact variant='section'/>
      {
        isOpen &&
        <VideoPopup data={videoData} handleClick={() => setIsOpen(false)}/>
      }
    </>
  )
}
