import styles from './Product.module.css';
import {Breadcrumbs} from "../../components/Breadcrumbs";
import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router";
import {useTranslation} from "react-i18next";
import {EffectFade} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";
import clsx from "clsx";
import {Button} from "../../components/Button";
import {OtherSlider} from "../../components";

export const Product = () => {
  const {t} = useTranslation();
  const {id} = useParams();
  const breadcrumbs = [{title: t("products.title"), to: '/products'}, {title: id, to: `/products/${id}`}];
  const mainSlider = useRef(null);
  const secondarySlider = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0)
  const product = t("products.items").find(item => item.title === id);
  const similarProducts = t('products.items')
    .filter(product => product.title !== id)
    .map(product => (
      {...product, mainPageImage: product.image}
    ))

  console.log(similarProducts)

  useEffect(() => {
    if (mainSlider.current && secondarySlider.current) {
      const swiper1 = mainSlider.current.swiper;
      const swiper2 = secondarySlider.current.swiper;

      swiper1.on('slideChange', () => {
        swiper2.slideTo(swiper1.activeIndex);
      });

      swiper2.on('slideChange', () => {
        swiper1.slideTo(swiper2.activeIndex);
      });
    }
  }, []);

  const handleSlide = (index) => {
    secondarySlider.current.swiper.slideTo(index)
    mainSlider.current.swiper.slideTo(index)
  }

  if (!product) {
    return null
  }

  return (
    <div className={styles.root}>
      <Breadcrumbs className={styles.breadcrumbs} links={breadcrumbs}/>
      <div className={styles.container}>
        <div className={clsx(styles.side, "kfn_anim", "k-fadeUp")}>
          <Swiper
            ref={mainSlider}
            modules={[EffectFade]}
            onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
            effect="fade"
          >
            {product.inner_page?.images.length && product.inner_page?.images.map((slide, index) => (
              <SwiperSlide className={styles.slide} key={index}>
                <img className={styles.image} src={require(`../../assets/images/products/${slide}`)} alt=""/>
                <div className={styles.layer}></div>
              </SwiperSlide>
            ))}
          </Swiper>
          <Swiper
            ref={secondarySlider}
            breakpoints={{
              320: {
                slidesPerView: 3.2,
                spaceBetween: 5,
              },
              768: {
                slidesPerView: 3.2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3.4,
                spaceBetween: 20,
              },
              1365: {
                slidesPerView: 3.4,
                spaceBetween: 20,
              },
              1440: {
                slidesPerView: 3.4,
                spaceBetween: 20,
              }
            }}
          >
            {
              product.inner_page?.images.length && product.inner_page?.images.map((item, index) => (
                <SwiperSlide key={index}
                             className={clsx(styles.secondarySliderItem, {[styles.activeImg]: activeIndex === index})}
                             onClick={() => handleSlide(index)}>
                  <div>
                    <img src={require(`../../assets/images/products/${item}`)} alt=""/>
                  </div>
                </SwiperSlide>
              ))
            }
          </Swiper>
        </div>
        <div className={styles.side}>
          <div className={clsx(styles.title, "kfn_anim", "k-fadeUp")}>{product.title}</div>
          <ul className={clsx(styles.list, "kfn_anim", "k-fadeUp")}>
            {
              product.inner_page?.description.map((item, index) => (
                <li key={index}>
                  <span className={clsx(styles.circle, "kfn_anim", "k-fadeUp")}></span>
                  <span>{item.title}</span>
                  <span>{item.text}</span>
                </li>
              ))
            }
          </ul>
          <div className={clsx(styles.info, "kfn_anim", "k-fadeUp")}>
            {product.inner_page.information}
          </div>
          <a className="kfn_anim k-fadeUp" href={`tel:${product.inner_page.phone}`}>
            <Button variant="secondary">{product.inner_page.call}</Button>
          </a>
        </div>
      </div>
      <OtherSlider className="kfn_anim k-fadeUp" items={similarProducts}
                   title={t("products.similarTitle")} path="products"/>
    </div>
  )
}
