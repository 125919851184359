export default {
  "menuItems": [
    {
      "name": "Homee",
      "path": "/"
    },
    {
      "name": "Services",
      "path": "/services"
    },
    {
      "name": "Projects",
      "path": "/projects"
    },
    {
      "name": "Companies",
      "path": "/companies"
    },
    {
      "name": "Gallery",
      "path": "/gallery"
    },
    {
      "name": "Contact Us",
      "path": "/contact"
    }
  ]
}
