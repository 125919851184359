import {Link} from "react-router-dom";
import clsx from "clsx";

import styles from './Logo.module.css';

export const Logo = ({clasName, isFooter = false}) => {
  return (
    <Link to="/" className={clsx(clasName, styles.root, {[styles.isFooter]: isFooter})}>
      <svg className={styles.logo} width="46" height="47" viewBox="0 0 46 47" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M16.8215 0.555712C9.59584 2.56983 3.33782 9.08279 1.40784 16.5971C0.631895 19.6183 0.639965 24.8983 1.42479 27.9546C2.02481 30.2908 4.3006 35.1338 4.79732 35.1322C5.35698 35.1305 7.33619 31.3801 7.12475 30.7225C7.00007 30.335 6.532 29.0051 6.08491 27.7672C4.49226 23.3576 5.221 16.8647 7.76593 12.7865C9.40054 10.168 12.4761 7.37066 15.1433 6.07634C26.9342 0.355609 40.3509 8.86795 40.3509 22.0698C40.3509 29.3648 36.5595 35.3597 29.9323 38.5437C27.412 39.7546 26.9471 39.8389 22.7773 39.8389C18.4194 39.8389 18.2463 39.8029 15.1856 38.2687C13.4627 37.4053 11.9596 36.4514 11.845 36.1486C11.7304 35.8458 12.8893 33.6062 14.4198 31.1714C15.9507 28.7362 18.4476 24.7591 19.9688 22.3334C21.4897 19.9072 22.8737 18.0657 23.044 18.2413C23.4168 18.6247 29.4666 28.1642 31.6161 31.757C32.4578 33.1639 33.279 34.3146 33.4412 34.3146C34.061 34.3146 36.4126 31.4783 36.2177 30.9656C36.1056 30.6693 34.0017 27.2046 31.5431 23.2659L27.0726 16.1048L34.7018 15.8707V11.8081H10.8947V15.9002H14.7281C16.8364 15.9002 18.5614 15.9882 18.5614 16.0954C18.5614 16.203 17.1298 18.5506 15.3797 21.3128C5.84644 36.361 0 45.9046 0 46.4193C0 46.8506 0.573789 47 2.23423 47H4.46846L9.10518 39.7284L10.097 40.5427C14.2572 43.9588 21.9961 45.3996 28.2081 43.9146C35.7501 42.1116 42.1957 35.6478 44.1886 27.8888C44.8782 25.2031 44.9642 19.4153 44.3541 16.7301C42.6852 9.38601 35.4797 2.07469 28.2456 0.385072C25.7467 -0.198459 19.1747 -0.100658 16.8215 0.555712ZM38.9995 39.5712L37.5166 41.1111L41.2321 47H43.6161C44.9271 47 46 46.8768 46 46.7267C46 46.3788 41.0917 38.4136 40.7427 38.1947C40.5994 38.1046 39.815 38.7242 38.9995 39.5712Z"
              fill="#B93900"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M16.8215 0.555712C9.59584 2.56983 3.33782 9.08279 1.40784 16.5971C0.631895 19.6183 0.639965 24.8983 1.42479 27.9546C2.02481 30.2908 4.3006 35.1338 4.79732 35.1322C5.35698 35.1305 7.33619 31.3801 7.12475 30.7225C7.00007 30.335 6.532 29.0051 6.08491 27.7672C4.49226 23.3576 5.221 16.8647 7.76593 12.7865C9.40054 10.168 12.4761 7.37066 15.1433 6.07634C26.9342 0.355609 40.3509 8.86795 40.3509 22.0698C40.3509 29.3648 36.5595 35.3597 29.9323 38.5437C27.412 39.7546 26.9471 39.8389 22.7773 39.8389C18.4194 39.8389 18.2463 39.8029 15.1856 38.2687C13.4627 37.4053 11.9596 36.4514 11.845 36.1486C11.7304 35.8458 12.8893 33.6062 14.4198 31.1714C15.9507 28.7362 18.4476 24.7591 19.9688 22.3334C21.4897 19.9072 22.8737 18.0657 23.044 18.2413C23.4168 18.6247 29.4666 28.1642 31.6161 31.757C32.4578 33.1639 33.279 34.3146 33.4412 34.3146C34.061 34.3146 36.4126 31.4783 36.2177 30.9656C36.1056 30.6693 34.0017 27.2046 31.5431 23.2659L27.0726 16.1048L34.7018 15.8707V11.8081H11.2982V15.9002H14.9298C16.9272 15.9002 18.5614 15.9882 18.5614 16.0954C18.5614 16.203 17.1298 18.5506 15.3797 21.3128C5.84644 36.361 0 45.9046 0 46.4193C0 46.8506 0.573789 47 2.23423 47H4.46846L9.10518 39.7284L10.097 40.5427C14.2572 43.9588 21.9961 45.3996 28.2081 43.9146C35.7501 42.1116 42.1957 35.6478 44.1886 27.8888C44.8782 25.2031 44.9642 19.4153 44.3541 16.7301C42.6852 9.38601 35.4797 2.07469 28.2456 0.385072C25.7467 -0.198459 19.1747 -0.100658 16.8215 0.555712ZM38.9995 39.5712L37.5166 41.1111L41.2321 47H43.6161C44.9271 47 46 46.8768 46 46.7267C46 46.3788 41.0917 38.4136 40.7427 38.1947C40.5994 38.1046 39.815 38.7242 38.9995 39.5712Z"
              fill="#B73700"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M16.8215 0.555712C9.59584 2.56983 3.33782 9.08279 1.40784 16.5971C0.631895 19.6183 0.639965 24.8983 1.42479 27.9546C2.02481 30.2908 4.3006 35.1338 4.79732 35.1322C5.35698 35.1305 7.33619 31.3801 7.12475 30.7225C7.00007 30.335 6.532 29.0051 6.08491 27.7672C4.49226 23.3576 5.221 16.8647 7.76593 12.7865C9.40054 10.168 12.4761 7.37066 15.1433 6.07634C26.9342 0.355609 40.3509 8.86795 40.3509 22.0698C40.3509 29.3648 36.5595 35.3597 29.9323 38.5437C27.412 39.7546 26.9471 39.8389 22.7773 39.8389C18.4194 39.8389 18.2463 39.8029 15.1856 38.2687C13.4627 37.4053 11.9596 36.4514 11.845 36.1486C11.7304 35.8458 12.8893 33.6062 14.4198 31.1714C15.9507 28.7362 18.4476 24.7591 19.9688 22.3334C21.4897 19.9072 22.8737 18.0657 23.044 18.2413C23.4168 18.6247 29.4666 28.1642 31.6161 31.757C32.4578 33.1639 33.279 34.3146 33.4412 34.3146C34.0678 34.3146 36.4118 31.4755 36.2145 30.9545C36.1003 30.6526 33.9068 27.0499 31.3401 22.9484L26.6735 15.491H34.7018V11.8081H11.2982V15.4615L18.9359 15.6956L15.5678 21.0153C5.68584 36.6233 0 45.9017 0 46.4193C0 46.8506 0.573789 47 2.23423 47H4.46846L9.10518 39.7284L10.097 40.5427C14.2572 43.9588 21.9961 45.3996 28.2081 43.9146C35.7501 42.1116 42.1957 35.6478 44.1886 27.8888C44.8782 25.2031 44.9642 19.4153 44.3541 16.7301C42.6852 9.38601 35.4797 2.07469 28.2456 0.385072C25.7467 -0.198459 19.1747 -0.100658 16.8215 0.555712ZM38.9995 39.5712L37.5166 41.1111L41.2321 47H43.6161C44.9271 47 46 46.8768 46 46.7267C46 46.3788 41.0917 38.4136 40.7427 38.1947C40.5994 38.1046 39.815 38.7242 38.9995 39.5712Z"
              fill="#C76238"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M16.8215 0.555712C9.59584 2.56983 3.33782 9.08279 1.40784 16.5971C0.631895 19.6183 0.639965 24.8983 1.42479 27.9546C2.02481 30.2908 4.3006 35.1338 4.79732 35.1322C5.35698 35.1305 7.33619 31.3801 7.12475 30.7225C7.00007 30.335 6.532 29.0051 6.08491 27.7672C4.49226 23.3576 5.221 16.8647 7.76593 12.7865C9.40054 10.168 12.4761 7.37066 15.1433 6.07634C26.9342 0.355609 40.3509 8.86795 40.3509 22.0698C40.3509 29.3648 36.5595 35.3597 29.9323 38.5437C27.412 39.7546 26.9471 39.8389 22.7773 39.8389C18.4194 39.8389 18.2463 39.8029 15.1856 38.2687C13.4627 37.4053 11.9596 36.4514 11.845 36.1486C11.7304 35.8458 12.8893 33.6062 14.4198 31.1714C15.9507 28.7362 18.4476 24.7591 19.9688 22.3334C21.4897 19.9072 22.8737 18.0657 23.044 18.2413C23.4168 18.6247 29.4666 28.1642 31.6161 31.757C32.4578 33.1639 33.279 34.3146 33.4412 34.3146C33.8439 34.3146 36.3158 31.6997 36.3158 31.2737C36.3158 31.0859 34.2099 27.5499 31.6363 23.4161L26.9564 15.9002L18.7632 15.9088L15.483 21.1221C3.98868 39.3904 0 45.9074 0 46.4193C0 46.8506 0.573789 47 2.23423 47H4.46846L9.10518 39.7284L10.097 40.5427C14.2572 43.9588 21.9961 45.3996 28.2081 43.9146C35.7501 42.1116 42.1957 35.6478 44.1886 27.8888C44.8782 25.2031 44.9642 19.4153 44.3541 16.7301C42.6852 9.38601 35.4797 2.07469 28.2456 0.385072C25.7467 -0.198459 19.1747 -0.100658 16.8215 0.555712ZM11.2982 15.491H34.7018V11.8081H11.2982V15.491ZM38.9995 39.5712L37.5166 41.1111L41.2321 47H43.6161C44.9271 47 46 46.8768 46 46.7267C46 46.3788 41.0917 38.4136 40.7427 38.1947C40.5994 38.1046 39.815 38.7242 38.9995 39.5712Z"
              fill="#B73600"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M16.8215 0.555712C9.59584 2.56983 3.33782 9.08279 1.40784 16.5971C0.631895 19.6183 0.639965 24.8983 1.42479 27.9546C2.02481 30.2908 4.3006 35.1338 4.79732 35.1322C5.35698 35.1305 7.33619 31.3801 7.12475 30.7225C7.00007 30.335 6.532 29.0051 6.08491 27.7672C4.49226 23.3576 5.221 16.8647 7.76593 12.7865C9.40054 10.168 12.4761 7.37066 15.1433 6.07634C26.9342 0.355609 40.3509 8.86795 40.3509 22.0698C40.3509 29.3648 36.5595 35.3597 29.9323 38.5437C27.412 39.7546 26.9471 39.8389 22.7773 39.8389C18.4194 39.8389 18.2463 39.8029 15.1856 38.2687C13.4627 37.4053 11.9596 36.4514 11.845 36.1486C11.7304 35.8458 12.8893 33.6062 14.4198 31.1714C15.9507 28.7362 18.4476 24.7591 19.9688 22.3334C21.4897 19.9072 22.8737 18.0657 23.044 18.2413C23.4168 18.6247 29.4666 28.1642 31.6161 31.757C32.4578 33.1639 33.279 34.3146 33.4412 34.3146C33.8439 34.3146 36.3158 31.6997 36.3158 31.2737C36.3158 31.0859 34.2099 27.5499 31.6363 23.4161L26.9564 15.9002L18.7632 15.9088L15.483 21.1221C3.98868 39.3904 0 45.9074 0 46.4193C0 46.8506 0.573789 47 2.23423 47H4.46846L9.10518 39.7284L10.097 40.5427C14.2572 43.9588 21.9961 45.3996 28.2081 43.9146C35.7501 42.1116 42.1957 35.6478 44.1886 27.8888C44.8782 25.2031 44.9642 19.4153 44.3541 16.7301C42.6852 9.38601 35.4797 2.07469 28.2456 0.385072C25.7467 -0.198459 19.1747 -0.100658 16.8215 0.555712ZM34.4136 13.6495C34.4169 14.7749 34.4952 15.1865 34.588 14.5645C34.6804 13.9425 34.6779 13.0218 34.5823 12.5185C34.4867 12.0152 34.4108 12.5242 34.4136 13.6495ZM38.9995 39.5712L37.5166 41.1111L41.2321 47H43.6161C44.9271 47 46 46.8768 46 46.7267C46 46.3788 41.0917 38.4136 40.7427 38.1947C40.5994 38.1046 39.815 38.7242 38.9995 39.5712Z"
              fill="#B73500"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M16.8215 0.555712C9.59584 2.56983 3.33782 9.08279 1.40784 16.5971C0.631895 19.6183 0.639965 24.8983 1.42479 27.9546C2.02481 30.2908 4.3006 35.1338 4.79732 35.1322C5.35698 35.1305 7.33619 31.3801 7.12475 30.7225C7.00007 30.335 6.532 29.0051 6.08491 27.7672C4.49226 23.3576 5.221 16.8647 7.76593 12.7865C9.40054 10.168 12.4761 7.37066 15.1433 6.07634C26.9342 0.355609 40.3509 8.86795 40.3509 22.0698C40.3509 29.3648 36.5595 35.3597 29.9323 38.5437C27.412 39.7546 26.9471 39.8389 22.7773 39.8389C18.4194 39.8389 18.2463 39.8029 15.1856 38.2687C13.4627 37.4053 11.9596 36.4514 11.845 36.1486C11.7304 35.8458 12.8893 33.6062 14.4198 31.1714C15.9507 28.7362 18.4476 24.7591 19.9688 22.3334C21.4897 19.9072 22.8737 18.0657 23.044 18.2413C23.4168 18.6247 29.4666 28.1642 31.6161 31.757C32.4578 33.1639 33.279 34.3146 33.4412 34.3146C33.8439 34.3146 36.3158 31.6997 36.3158 31.2737C36.3158 31.0859 34.2099 27.5499 31.6363 23.4161L26.9564 15.9002L18.7632 15.9088L15.483 21.1221C3.98868 39.3904 0 45.9074 0 46.4193C0 46.8506 0.573789 47 2.23423 47H4.46846L9.10518 39.7284L10.097 40.5427C14.2572 43.9588 21.9961 45.3996 28.2081 43.9146C35.7501 42.1116 42.1957 35.6478 44.1886 27.8888C44.8782 25.2031 44.9642 19.4153 44.3541 16.7301C42.6852 9.38601 35.4797 2.07469 28.2456 0.385072C25.7467 -0.198459 19.1747 -0.100658 16.8215 0.555712ZM38.9995 39.5712L37.5166 41.1111L41.2321 47H43.6161C44.9271 47 46 46.8768 46 46.7267C46 46.3788 41.0917 38.4136 40.7427 38.1947C40.5994 38.1046 39.815 38.7242 38.9995 39.5712Z"
              fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M16.8215 0.555712C9.59584 2.56983 3.33782 9.08279 1.40784 16.5971C0.631895 19.6183 0.639965 24.8983 1.42479 27.9546C2.02481 30.2908 4.3006 35.1338 4.79732 35.1322C5.35698 35.1305 7.33619 31.3801 7.12475 30.7225C7.00007 30.335 6.532 29.0051 6.08491 27.7672C4.49226 23.3576 5.221 16.8647 7.76593 12.7865C9.40054 10.168 12.4761 7.37066 15.1433 6.07634C26.9342 0.355609 40.3509 8.86795 40.3509 22.0698C40.3509 29.3648 36.5595 35.3597 29.9323 38.5437C27.412 39.7546 26.9471 39.8389 22.7773 39.8389C18.4194 39.8389 18.2463 39.8029 15.1856 38.2687C13.4627 37.4053 11.9596 36.4514 11.845 36.1486C11.7304 35.8458 12.8893 33.6062 14.4198 31.1714C15.9507 28.7362 18.4476 24.7591 19.9688 22.3334C21.4897 19.9072 22.8737 18.0657 23.044 18.2413C23.4168 18.6247 29.4666 28.1642 31.6161 31.757C32.4578 33.1639 33.279 34.3146 33.4412 34.3146C33.8439 34.3146 36.3158 31.6997 36.3158 31.2737C36.3158 31.0859 34.2099 27.5499 31.6363 23.4161L26.9564 15.9002L18.7632 15.9088L15.483 21.1221C3.98868 39.3904 0 45.9074 0 46.4193C0 46.8506 0.573789 47 2.23423 47H4.46846L9.10518 39.7284L10.097 40.5427C14.2572 43.9588 21.9961 45.3996 28.2081 43.9146C35.7501 42.1116 42.1957 35.6478 44.1886 27.8888C44.8782 25.2031 44.9642 19.4153 44.3541 16.7301C42.6852 9.38601 35.4797 2.07469 28.2456 0.385072C25.7467 -0.198459 19.1747 -0.100658 16.8215 0.555712ZM38.9995 39.5712L37.5166 41.1111L41.2321 47H43.6161C44.9271 47 46 46.8768 46 46.7267C46 46.3788 41.0917 38.4136 40.7427 38.1947C40.5994 38.1046 39.815 38.7242 38.9995 39.5712Z"
              fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M16.8215 0.555712C9.59584 2.56983 3.33782 9.08279 1.40784 16.5971C0.631895 19.6183 0.639965 24.8983 1.42479 27.9546C2.02481 30.2908 4.3006 35.1338 4.79732 35.1322C5.35698 35.1305 7.33619 31.3801 7.12475 30.7225C7.00007 30.335 6.532 29.0051 6.08491 27.7672C4.49226 23.3576 5.221 16.8647 7.76593 12.7865C9.40054 10.168 12.4761 7.37066 15.1433 6.07634C26.9342 0.355609 40.3509 8.86795 40.3509 22.0698C40.3509 29.3648 36.5595 35.3597 29.9323 38.5437C27.412 39.7546 26.9471 39.8389 22.7773 39.8389C18.4194 39.8389 18.2463 39.8029 15.1856 38.2687C13.4627 37.4053 11.9596 36.4514 11.845 36.1486C11.7304 35.8458 12.8893 33.6062 14.4198 31.1714C15.9507 28.7362 18.4476 24.7591 19.9688 22.3334C21.4897 19.9072 22.8737 18.0657 23.044 18.2413C23.4168 18.6247 29.4666 28.1642 31.6161 31.757C32.4578 33.1639 33.279 34.3146 33.4412 34.3146C33.8439 34.3146 36.3158 31.6997 36.3158 31.2737C36.3158 31.0859 34.2099 27.5499 31.6363 23.4161L26.9564 15.9002L18.7632 15.9088L15.483 21.1221C3.98868 39.3904 0 45.9074 0 46.4193C0 46.8506 0.573789 47 2.23423 47H4.46846L9.10518 39.7284L10.097 40.5427C14.2572 43.9588 21.9961 45.3996 28.2081 43.9146C35.7501 42.1116 42.1957 35.6478 44.1886 27.8888C44.8782 25.2031 44.9642 19.4153 44.3541 16.7301C42.6852 9.38601 35.4797 2.07469 28.2456 0.385072C25.7467 -0.198459 19.1747 -0.100658 16.8215 0.555712ZM38.9995 39.5712L37.5166 41.1111L41.2321 47H43.6161C44.9271 47 46 46.8768 46 46.7267C46 46.3788 41.0917 38.4136 40.7427 38.1947C40.5994 38.1046 39.815 38.7242 38.9995 39.5712Z"
              fill="white"/>
      </svg>
      <svg className={styles.logoText} width="93" height="31" viewBox="0 0 93 31" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M13.0677 1.07574C9.45908 8.77305 0 29.7367 0 30.0369C0 30.2543 0.890028 30.4323 1.97748 30.4323H3.95495L8.32472 20.6599L20.0081 20.8838L24.3089 30.2291L26.2774 30.3551C28.159 30.4753 28.2349 30.4392 28.0069 29.5335C27.8755 29.0126 26.2346 25.2078 24.3607 21.0784C22.4864 16.9486 19.5883 10.5121 17.9204 6.77437C14.7503 -0.330117 14.0903 -1.10537 13.0677 1.07574ZM74.4889 0.816513C71.9632 1.4073 68.1768 3.62133 66.6459 5.40263C65.8701 6.30547 64.737 8.05549 64.1276 9.29151C63.136 11.3028 63.0197 11.9858 63.0197 15.8048C63.0197 19.6965 63.1242 20.2832 64.2104 22.4871C69.75 33.7255 86.3228 33.8685 91.897 22.726C92.9058 20.7091 93 20.1175 93 15.7898C93 11.3337 92.9282 10.9156 91.7616 8.5898C88.7555 2.59741 81.273 -0.769755 74.4889 0.816513ZM31.4079 30.4323H35.0789V1.17733H31.4079V30.4323ZM40.7895 30.4323H44.4605L44.4768 18.0396L46.6101 20.2771C47.7836 21.5075 50.4431 24.3046 52.5201 26.4926L56.2968 30.4709L61.234 30.2291L53.867 22.4298C49.815 18.1403 46.5061 14.4834 46.5135 14.3035C46.5212 14.1235 49.5351 11.2337 53.2111 7.88158C56.887 4.52945 59.9095 1.64987 59.9279 1.48206C59.9458 1.31425 58.8433 1.17733 57.4777 1.17733H54.9952L44.6645 10.9371L44.4352 1.17733H40.7895V30.4323ZM83.2607 5.47333C88.5813 8.14732 91.0601 14.954 88.7334 20.5006C87.8715 22.5558 85.2378 25.1883 83.0066 26.2257C80.0632 27.5937 75.9704 27.558 73.1115 26.1387C68.0054 23.6041 65.5834 17.7742 67.2549 12.0423C67.8774 9.9083 70.2244 6.98687 72.3862 5.65618C75.3304 3.84359 79.8666 3.7672 83.2607 5.47333ZM16.3223 12.731L18.2851 17.0238H14.2074C11.9644 17.0238 10.1904 16.8649 10.2655 16.6707C10.9785 14.8179 14.105 8.19282 14.216 8.29969C14.2947 8.37607 15.2426 10.3699 16.3223 12.731Z"
              fill="#B93900"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M13.0677 1.07574C9.45908 8.77305 0 29.7367 0 30.0369C0 30.2543 0.890028 30.4323 1.97748 30.4323H3.95495L8.32472 20.6599L20.0081 20.8838L24.3089 30.2291L26.2774 30.3551C28.159 30.4753 28.2349 30.4392 28.0069 29.5335C27.8755 29.0126 26.2346 25.2078 24.3607 21.0784C22.4864 16.9486 19.5883 10.5121 17.9204 6.77437C14.7503 -0.330117 14.0903 -1.10537 13.0677 1.07574ZM74.4889 0.816513C71.9632 1.4073 68.1768 3.62133 66.6459 5.40263C65.8701 6.30547 64.737 8.05549 64.1276 9.29151C63.136 11.3028 63.0197 11.9858 63.0197 15.8048C63.0197 19.6965 63.1242 20.2832 64.2104 22.4871C69.75 33.7255 86.3228 33.8685 91.897 22.726C92.9058 20.7091 93 20.1175 93 15.7898C93 11.3337 92.9282 10.9156 91.7616 8.5898C88.7555 2.59741 81.273 -0.769755 74.4889 0.816513ZM31.4079 30.4323H35.0789V1.17733H31.4079V30.4323ZM40.7895 30.4323H44.4605L44.4768 18.0396L46.6101 20.2771C47.7836 21.5075 50.4431 24.3046 52.5201 26.4926L56.2968 30.4709L61.234 30.2291L53.867 22.4298C49.815 18.1403 46.5061 14.4834 46.5135 14.3035C46.5212 14.1235 49.5351 11.2337 53.2111 7.88158C56.887 4.52945 59.9095 1.64987 59.9279 1.48206C59.9458 1.31425 58.8433 1.17733 57.4777 1.17733H54.9952L44.6645 10.9371L44.4352 1.17733H40.7895V30.4323ZM83.2607 5.47333C88.5813 8.14732 91.0601 14.954 88.7334 20.5006C87.8715 22.5558 85.2378 25.1883 83.0066 26.2257C80.0632 27.5937 75.9704 27.558 73.1115 26.1387C68.0054 23.6041 65.5834 17.7742 67.2549 12.0423C67.8774 9.9083 70.2244 6.98687 72.3862 5.65618C75.3304 3.84359 79.8666 3.7672 83.2607 5.47333ZM16.3223 12.731L18.2851 17.0238H14.2074C11.9644 17.0238 10.1904 16.8649 10.2655 16.6707C10.9785 14.8179 14.105 8.19282 14.216 8.29969C14.2947 8.37607 15.2426 10.3699 16.3223 12.731Z"
              fill="#B73700"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M13.0677 1.07574C9.45908 8.77305 0 29.7367 0 30.0369C0 30.2543 0.890028 30.4323 1.97748 30.4323H3.95495L8.32472 20.6599L20.0081 20.8838L24.3089 30.2291L26.2774 30.3551C28.159 30.4753 28.2349 30.4392 28.0069 29.5335C27.8755 29.0126 26.2346 25.2078 24.3607 21.0784C22.4864 16.9486 19.5883 10.5121 17.9204 6.77437C14.7503 -0.330117 14.0903 -1.10537 13.0677 1.07574ZM74.4889 0.816513C71.9632 1.4073 68.1768 3.62133 66.6459 5.40263C65.8701 6.30547 64.737 8.05549 64.1276 9.29151C63.136 11.3028 63.0197 11.9858 63.0197 15.8048C63.0197 19.6965 63.1242 20.2832 64.2104 22.4871C69.75 33.7255 86.3228 33.8685 91.897 22.726C92.9058 20.7091 93 20.1175 93 15.7898C93 11.3337 92.9282 10.9156 91.7616 8.5898C88.7555 2.59741 81.273 -0.769755 74.4889 0.816513ZM31.4079 30.4323H35.0789V1.17733H31.4079V30.4323ZM40.7895 30.4323H44.4605L44.4768 18.0396L46.6101 20.2771C47.7836 21.5075 50.4431 24.3046 52.5201 26.4926L56.2968 30.4709L61.234 30.2291L53.867 22.4298C49.815 18.1403 46.5061 14.4834 46.5135 14.3035C46.5212 14.1235 49.5351 11.2337 53.2111 7.88158C56.887 4.52945 59.9095 1.64987 59.9279 1.48206C59.9458 1.31425 58.8433 1.17733 57.4777 1.17733H54.9952L44.6645 10.9371L44.4352 1.17733H40.7895V30.4323ZM83.2607 5.47333C88.5813 8.14732 91.0601 14.954 88.7334 20.5006C87.8715 22.5558 85.2378 25.1883 83.0066 26.2257C80.0632 27.5937 75.9704 27.558 73.1115 26.1387C68.0054 23.6041 65.5834 17.7742 67.2549 12.0423C67.8774 9.9083 70.2244 6.98687 72.3862 5.65618C75.3304 3.84359 79.8666 3.7672 83.2607 5.47333ZM16.3223 12.731L18.2851 17.0238H14.2074C11.9644 17.0238 10.1904 16.8649 10.2655 16.6707C10.9785 14.8179 14.105 8.19282 14.216 8.29969C14.2947 8.37607 15.2426 10.3699 16.3223 12.731Z"
              fill="#C76238"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M13.0677 1.07574C9.45908 8.77305 0 29.7367 0 30.0369C0 30.2543 0.890028 30.4323 1.97748 30.4323H3.95495L8.32472 20.6599L20.0081 20.8838L24.3089 30.2291L26.2774 30.3551C28.159 30.4753 28.2349 30.4392 28.0069 29.5335C27.8755 29.0126 26.2346 25.2078 24.3607 21.0784C22.4864 16.9486 19.5883 10.5121 17.9204 6.77437C14.7503 -0.330117 14.0903 -1.10537 13.0677 1.07574ZM74.4889 0.816513C71.9632 1.4073 68.1768 3.62133 66.6459 5.40263C65.8701 6.30547 64.737 8.05549 64.1276 9.29151C63.136 11.3028 63.0197 11.9858 63.0197 15.8048C63.0197 19.6965 63.1242 20.2832 64.2104 22.4871C69.75 33.7255 86.3228 33.8685 91.897 22.726C92.9058 20.7091 93 20.1175 93 15.7898C93 11.3337 92.9282 10.9156 91.7616 8.5898C88.7555 2.59741 81.273 -0.769755 74.4889 0.816513ZM31.4079 30.4323H35.0789V1.17733H31.4079V30.4323ZM40.7895 30.4323H44.4605L44.4768 18.0396L46.6101 20.2771C47.7836 21.5075 50.4431 24.3046 52.5201 26.4926L56.2968 30.4709L61.234 30.2291L53.867 22.4298C49.815 18.1403 46.5061 14.4834 46.5135 14.3035C46.5212 14.1235 49.5351 11.2337 53.2111 7.88158C56.887 4.52945 59.9095 1.64987 59.9279 1.48206C59.9458 1.31425 58.8433 1.17733 57.4777 1.17733H54.9952L44.6645 10.9371L44.4352 1.17733H40.7895V30.4323ZM83.2607 5.47333C88.5813 8.14732 91.0601 14.954 88.7334 20.5006C87.8715 22.5558 85.2378 25.1883 83.0066 26.2257C80.0632 27.5937 75.9704 27.558 73.1115 26.1387C68.0054 23.6041 65.5834 17.7742 67.2549 12.0423C67.8774 9.9083 70.2244 6.98687 72.3862 5.65618C75.3304 3.84359 79.8666 3.7672 83.2607 5.47333ZM16.3223 12.731L18.2851 17.0238H14.2074C11.9644 17.0238 10.1904 16.8649 10.2655 16.6707C10.9785 14.8179 14.105 8.19282 14.216 8.29969C14.2947 8.37607 15.2426 10.3699 16.3223 12.731Z"
              fill="#B73600"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M13.0677 1.07574C9.45908 8.77305 0 29.7367 0 30.0369C0 30.2543 0.890028 30.4323 1.97748 30.4323H3.95495L8.32472 20.6599L20.0081 20.8838L24.3089 30.2291L26.2774 30.3551C28.159 30.4753 28.2349 30.4392 28.0069 29.5335C27.8755 29.0126 26.2346 25.2078 24.3607 21.0784C22.4864 16.9486 19.5883 10.5121 17.9204 6.77437C14.7503 -0.330117 14.0903 -1.10537 13.0677 1.07574ZM74.4889 0.816513C71.9632 1.4073 68.1768 3.62133 66.6459 5.40263C65.8701 6.30547 64.737 8.05549 64.1276 9.29151C63.136 11.3028 63.0197 11.9858 63.0197 15.8048C63.0197 19.6965 63.1242 20.2832 64.2104 22.4871C69.75 33.7255 86.3228 33.8685 91.897 22.726C92.9058 20.7091 93 20.1175 93 15.7898C93 11.3337 92.9282 10.9156 91.7616 8.5898C88.7555 2.59741 81.273 -0.769755 74.4889 0.816513ZM31.4079 30.4323H35.0789V1.17733H31.4079V30.4323ZM40.7895 30.4323H44.4605L44.4768 18.0396L46.6101 20.2771C47.7836 21.5075 50.4431 24.3046 52.5201 26.4926L56.2968 30.4709L61.234 30.2291L53.867 22.4298C49.815 18.1403 46.5061 14.4834 46.5135 14.3035C46.5212 14.1235 49.5351 11.2337 53.2111 7.88158C56.887 4.52945 59.9095 1.64987 59.9279 1.48206C59.9458 1.31425 58.8433 1.17733 57.4777 1.17733H54.9952L44.6645 10.9371L44.4352 1.17733H40.7895V30.4323ZM83.2607 5.47333C88.5813 8.14732 91.0601 14.954 88.7334 20.5006C87.8715 22.5558 85.2378 25.1883 83.0066 26.2257C80.0632 27.5937 75.9704 27.558 73.1115 26.1387C68.0054 23.6041 65.5834 17.7742 67.2549 12.0423C67.8774 9.9083 70.2244 6.98687 72.3862 5.65618C75.3304 3.84359 79.8666 3.7672 83.2607 5.47333ZM16.3223 12.731L18.2851 17.0238H14.2074C11.9644 17.0238 10.1904 16.8649 10.2655 16.6707C10.9785 14.8179 14.105 8.19282 14.216 8.29969C14.2947 8.37607 15.2426 10.3699 16.3223 12.731Z"
              fill="#B73500"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M13.0677 1.07574C9.45908 8.77305 0 29.7367 0 30.0369C0 30.2543 0.890028 30.4323 1.97748 30.4323H3.95495L8.32472 20.6599L20.0081 20.8838L24.3089 30.2291L26.2774 30.3551C28.159 30.4753 28.2349 30.4392 28.0069 29.5335C27.8755 29.0126 26.2346 25.2078 24.3607 21.0784C22.4864 16.9486 19.5883 10.5121 17.9204 6.77437C14.7503 -0.330117 14.0903 -1.10537 13.0677 1.07574ZM74.4889 0.816513C71.9632 1.4073 68.1768 3.62133 66.6459 5.40263C65.8701 6.30547 64.737 8.05549 64.1276 9.29151C63.136 11.3028 63.0197 11.9858 63.0197 15.8048C63.0197 19.6965 63.1242 20.2832 64.2104 22.4871C69.75 33.7255 86.3228 33.8685 91.897 22.726C92.9058 20.7091 93 20.1175 93 15.7898C93 11.3337 92.9282 10.9156 91.7616 8.5898C88.7555 2.59741 81.273 -0.769755 74.4889 0.816513ZM31.4079 30.4323H35.0789V1.17733H31.4079V30.4323ZM40.7895 30.4323H44.4605L44.4768 18.0396L46.6101 20.2771C47.7836 21.5075 50.4431 24.3046 52.5201 26.4926L56.2968 30.4709L61.234 30.2291L53.867 22.4298C49.815 18.1403 46.5061 14.4834 46.5135 14.3035C46.5212 14.1235 49.5351 11.2337 53.2111 7.88158C56.887 4.52945 59.9095 1.64987 59.9279 1.48206C59.9458 1.31425 58.8433 1.17733 57.4777 1.17733H54.9952L44.6645 10.9371L44.4352 1.17733H40.7895V30.4323ZM83.2607 5.47333C88.5813 8.14732 91.0601 14.954 88.7334 20.5006C87.8715 22.5558 85.2378 25.1883 83.0066 26.2257C80.0632 27.5937 75.9704 27.558 73.1115 26.1387C68.0054 23.6041 65.5834 17.7742 67.2549 12.0423C67.8774 9.9083 70.2244 6.98687 72.3862 5.65618C75.3304 3.84359 79.8666 3.7672 83.2607 5.47333ZM16.3223 12.731L18.2851 17.0238H14.2074C11.9644 17.0238 10.1904 16.8649 10.2655 16.6707C10.9785 14.8179 14.105 8.19282 14.216 8.29969C14.2947 8.37607 15.2426 10.3699 16.3223 12.731Z"
              fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M13.0677 1.07574C9.45908 8.77305 0 29.7367 0 30.0369C0 30.2543 0.890028 30.4323 1.97748 30.4323H3.95495L8.32472 20.6599L20.0081 20.8838L24.3089 30.2291L26.2774 30.3551C28.159 30.4753 28.2349 30.4392 28.0069 29.5335C27.8755 29.0126 26.2346 25.2078 24.3607 21.0784C22.4864 16.9486 19.5883 10.5121 17.9204 6.77437C14.7503 -0.330117 14.0903 -1.10537 13.0677 1.07574ZM74.4889 0.816513C71.9632 1.4073 68.1768 3.62133 66.6459 5.40263C65.8701 6.30547 64.737 8.05549 64.1276 9.29151C63.136 11.3028 63.0197 11.9858 63.0197 15.8048C63.0197 19.6965 63.1242 20.2832 64.2104 22.4871C69.75 33.7255 86.3228 33.8685 91.897 22.726C92.9058 20.7091 93 20.1175 93 15.7898C93 11.3337 92.9282 10.9156 91.7616 8.5898C88.7555 2.59741 81.273 -0.769755 74.4889 0.816513ZM31.4079 30.4323H35.0789V1.17733H31.4079V30.4323ZM40.7895 30.4323H44.4605L44.4768 18.0396L46.6101 20.2771C47.7836 21.5075 50.4431 24.3046 52.5201 26.4926L56.2968 30.4709L61.234 30.2291L53.867 22.4298C49.815 18.1403 46.5061 14.4834 46.5135 14.3035C46.5212 14.1235 49.5351 11.2337 53.2111 7.88158C56.887 4.52945 59.9095 1.64987 59.9279 1.48206C59.9458 1.31425 58.8433 1.17733 57.4777 1.17733H54.9952L44.6645 10.9371L44.4352 1.17733H40.7895V30.4323ZM83.2607 5.47333C88.5813 8.14732 91.0601 14.954 88.7334 20.5006C87.8715 22.5558 85.2378 25.1883 83.0066 26.2257C80.0632 27.5937 75.9704 27.558 73.1115 26.1387C68.0054 23.6041 65.5834 17.7742 67.2549 12.0423C67.8774 9.9083 70.2244 6.98687 72.3862 5.65618C75.3304 3.84359 79.8666 3.7672 83.2607 5.47333ZM16.3223 12.731L18.2851 17.0238H14.2074C11.9644 17.0238 10.1904 16.8649 10.2655 16.6707C10.9785 14.8179 14.105 8.19282 14.216 8.29969C14.2947 8.37607 15.2426 10.3699 16.3223 12.731Z"
              fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M13.0677 1.07574C9.45908 8.77305 0 29.7367 0 30.0369C0 30.2543 0.890028 30.4323 1.97748 30.4323H3.95495L8.32472 20.6599L20.0081 20.8838L24.3089 30.2291L26.2774 30.3551C28.159 30.4753 28.2349 30.4392 28.0069 29.5335C27.8755 29.0126 26.2346 25.2078 24.3607 21.0784C22.4864 16.9486 19.5883 10.5121 17.9204 6.77437C14.7503 -0.330117 14.0903 -1.10537 13.0677 1.07574ZM74.4889 0.816513C71.9632 1.4073 68.1768 3.62133 66.6459 5.40263C65.8701 6.30547 64.737 8.05549 64.1276 9.29151C63.136 11.3028 63.0197 11.9858 63.0197 15.8048C63.0197 19.6965 63.1242 20.2832 64.2104 22.4871C69.75 33.7255 86.3228 33.8685 91.897 22.726C92.9058 20.7091 93 20.1175 93 15.7898C93 11.3337 92.9282 10.9156 91.7616 8.5898C88.7555 2.59741 81.273 -0.769755 74.4889 0.816513ZM31.4079 30.4323H35.0789V1.17733H31.4079V30.4323ZM40.7895 30.4323H44.4605L44.4768 18.0396L46.6101 20.2771C47.7836 21.5075 50.4431 24.3046 52.5201 26.4926L56.2968 30.4709L61.234 30.2291L53.867 22.4298C49.815 18.1403 46.5061 14.4834 46.5135 14.3035C46.5212 14.1235 49.5351 11.2337 53.2111 7.88158C56.887 4.52945 59.9095 1.64987 59.9279 1.48206C59.9458 1.31425 58.8433 1.17733 57.4777 1.17733H54.9952L44.6645 10.9371L44.4352 1.17733H40.7895V30.4323ZM83.2607 5.47333C88.5813 8.14732 91.0601 14.954 88.7334 20.5006C87.8715 22.5558 85.2378 25.1883 83.0066 26.2257C80.0632 27.5937 75.9704 27.558 73.1115 26.1387C68.0054 23.6041 65.5834 17.7742 67.2549 12.0423C67.8774 9.9083 70.2244 6.98687 72.3862 5.65618C75.3304 3.84359 79.8666 3.7672 83.2607 5.47333ZM16.3223 12.731L18.2851 17.0238H14.2074C11.9644 17.0238 10.1904 16.8649 10.2655 16.6707C10.9785 14.8179 14.105 8.19282 14.216 8.29969C14.2947 8.37607 15.2426 10.3699 16.3223 12.731Z"
              fill="white"/>
      </svg>
    </Link>
  )
}
