import styles from './Projects.module.css';
import {ProjectItem, Title} from "../../components";
import clsx from "clsx";
import {useTranslation} from "react-i18next";

export const Projects = () => {
  const {t} = useTranslation();

  return (
    <div className={clsx('page', styles.container)}>
      <Title title={t("projects.title")} hasRectangle={true} className={styles.title} rectangleLeft={true}/>
      <div className={styles.projects}>
        {
          t("projects.projects").map((project, index) => (
            <div key={index} className={styles.projectItemContent}>
              <ProjectItem project={project} className={styles.project}/>
            </div>
          ))
        }
      </div>
    </div>
  )
}
