import {Outlet} from "react-router-dom";
import {Footer, Header} from "../../components";

import styles from './Layout.module.css'

export const Layout = () => {
  return (
    <div className={styles.root}>
      <Header/>
        <main className={styles.main}>
            <Outlet/>
        </main>
      <Footer/>
    </div>
  )
};
