import {useState} from "react";
import styles from "./Products.module.css";
import {Title} from "../../components";
import clsx from "clsx";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import {useMedia} from "use-media";
import ReactPaginate from "react-paginate";
import './Pagination.css';

export const Products = () => {
  const {t} = useTranslation();
  const isDesktop = useMedia("minWidth: 768");
  const itemsPerPage = 9;

  //TODO: for testing. should remove in future.
  const items = [...t("products.items"), ...t("products.items"), ...t("products.items"), ...t("products.items"), ...t("products.items")]
  const Items = ({currentItems}) => {
    return (
      <div className={styles.products}>
        {currentItems &&
          currentItems.map((item, index) => (
            <NavLink className={clsx(styles.product, "kfn_anim", "k-fadeUp")} key={index}
                     to={`/products/${item.title}`}>
              <div className={styles.image}>
                <img src={require(`../../assets/images/products/${isDesktop ? item.image : item.image_mobile}`)}
                     alt=""/>
              </div>
              <div className={styles.productTitle}>{item.title}</div>
            </NavLink>
          ))}
      </div>
    );
  }

  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  return (
    <div className={clsx('page', styles.container)}>
      <Title title={t('products.title')} hasRectangle={true} className={styles.title} rectangleLeft={true}/>
      <Items currentItems={currentItems}/>
      <div className={styles.pagination}>
        <ReactPaginate
          className="paginationList kfn_anim k-fadeUp"
          breakLabel="..."
          nextLabel={
            <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 8.81667L3.7085 5L0 1.175L1.1417 0L6 5L1.1417 10L0 8.81667Z" fill="#CCCCCC"/>
            </svg>
          }
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          pageCount={pageCount}
          previousLabel={
            <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 8.81667L2.2915 5L6 1.175L4.8583 0L0 5L4.8583 10L6 8.81667Z" fill="#CCCCCC"/>
            </svg>
          }
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  )
}
