import styles from './OtherSlider.module.css';
import {Swiper, SwiperSlide} from "swiper/react";
import clsx from "clsx";
import {NavLink} from "react-router-dom";

export const OtherSlider = ({className, items, title, path}) => {
  return (
    <div className={clsx(styles.root, className)}>
      <div className={styles.title}>
        <svg width="162" height="75" viewBox="0 0 162 75" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.2"
                d="M0 1H56.0347C72.6033 1 86.0348 14.4315 86.0348 31V44C86.0348 60.5685 99.4662 74 116.035 74H162"
                stroke="#FCFCFC"/>
        </svg>
        <span>{title}</span>
      </div>
      <div className={styles.container}>
        <Swiper
          breakpoints={{
            320: {
              slidesPerView: 2.2,
              spaceBetween: 15,
            },
            768: {
              slidesPerView: 2.5,
              spaceBetween: 20,
            },
            1025: {
              slidesPerView: 2.9,
              spaceBetween: 60,
            }
          }}
        >
          {
            items.map((slide, index) => (
              <SwiperSlide key={index}>
                <div className={styles.slide}>
                  <NavLink to={`/projects/${slide.title}`}>
                    <img src={require(`../../assets/images/${path}/${slide.mainPageImage}`)} alt=""/>
                    <div className={styles.layer}></div>
                    <div className={styles.text}>{slide.title}</div>
                  </NavLink>
                </div>
              </SwiperSlide>
            ))
          }
        </Swiper>
      </div>
    </div>
  )
}
