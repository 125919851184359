import React, {forwardRef} from 'react';

import styles from './Radio.module.css'

export const Radio =  forwardRef(({label, ...rest}, ref) => (
        <label className={styles.wrapper}>
            <input className={styles.input} type="radio" {...rest} ref={ref}/>
            <span className={styles.checkmark}/>
            {label}
        </label>
    ));