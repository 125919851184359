import {OtherSlider, ProjectSlider, Title} from "../../components";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router";
import clsx from "clsx";
import styles from './Project.module.css';
import {Breadcrumbs} from "../../components/Breadcrumbs";
import React from "react";

export const Project = () => {
  const {t} = useTranslation();
  const {id} = useParams();
  const breadcrumbs = [{title: 'Projects', to: '/projects'}, {title: id, to: `/company/${id}`}];

  if (!t('projects.projects')) {
    return null;
  }

  const project = t('projects.projects').find(project => project.title === id);
  const similarProjects = t('projects.projects').filter(project => project.title !== id);
  const hasInnerText = project.innerText;

  return (
    <div>
      <Breadcrumbs links={breadcrumbs} className={styles.breadcrumbs}/>
      <ProjectSlider project={project}/>
      {
        hasInnerText && <div className={styles.texts}>
          <Title title={project.innerText.title} className={styles.title}/>
          <div className={clsx(styles.text, "kfn_anim", "k-fadeUp")}
               dangerouslySetInnerHTML={{__html: project.innerText.text}}></div>
        </div>
      }
      <OtherSlider className={clsx({[styles.notText]: !hasInnerText}, "kfn_anim", "k-fadeUp")} items={similarProjects}
                   title={t("projects.otherProjects")} path="projects"/>
    </div>
  )
}
