import clsx from "clsx";

import styles from './BurgerMenuButton.module.css';

export const BurgerMenuButton = ({className, handleClick, isOpen, handleHover}) => {
  return (
    <button className={clsx(className, styles.root, {[styles.isOpen]: isOpen})} onClick={handleClick}
            onMouseEnter={handleHover}>
      {isOpen ?
        <svg width="25" height="17" viewBox="0 0 25 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0 1.41667C0 0.634263 0.634263 0 1.41667 0H23.5833C24.3657 0 25 0.634263 25 1.41667C25 2.19907 24.3657 2.83333 23.5833 2.83333H1.41667C0.634262 2.83333 0 2.19907 0 1.41667ZM0 8.5C0 7.7176 0.634263 7.08333 1.41667 7.08333H23.5833C24.3657 7.08333 25 7.7176 25 8.5C25 9.2824 24.3657 9.91667 23.5833 9.91667H1.41667C0.634262 9.91667 0 9.2824 0 8.5ZM0 15.5833C0 14.8009 0.634263 14.1667 1.41667 14.1667H23.5833C24.3657 14.1667 25 14.8009 25 15.5833C25 16.3657 24.3657 17 23.5833 17H1.41667C0.634262 17 0 16.3657 0 15.5833Z"
            fill="#CCCCCC"/>
        </svg> :
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M18 1.81286L16.1871 0L9 7.18714L1.81286 0L0 1.81286L7.18714 9L0 16.1871L1.81286 18L9 10.8129L16.1871 18L18 16.1871L10.8129 9L18 1.81286Z"
            fill="white"/>
        </svg>
      }
    </button>
  )
}
