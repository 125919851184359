import styles from './ProductsSlider.module.css';
import {Title} from "../Title";
import {useTranslation} from "react-i18next";
import {Swiper, SwiperSlide} from "swiper/react";
import clsx from "clsx";
import {NavLink} from "react-router-dom";

export const ProductsSlider = () => {
  const {t} = useTranslation();

  return (
    <div className={styles.root}>
      <Title title={t('products.title')} hasRectangle/>
      <div className={clsx(styles.slider, "kfn_anim", "k-fadeUp")}>
        <div className={clsx(styles.arrow, styles.left, "sliderPrevProducts")}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 6.9899V9.0101H12.1212L6.56566 14.5657L8 16L16 8L8 0L6.56566 1.43434L12.1212 6.9899H0Z"
                  fill="#100C08"/>
          </svg>
        </div>
        <div className={clsx(styles.arrow, styles.right, "sliderNextProducts")}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 6.9899V9.0101H12.1212L6.56566 14.5657L8 16L16 8L8 0L6.56566 1.43434L12.1212 6.9899H0Z"
                  fill="#100C08"/>
          </svg>
        </div>
        <Swiper
          slidesPerView={'auto'}
          spaceBetween={30}
          navigation={{prevEl: '.sliderPrevProducts', nextEl: '.sliderNextProducts'}}
        >
          {
            t("projects.projectsMainPageSlider").map((slide, index) => (
              <SwiperSlide className={clsx(styles.swiperSlide, "swiperSlideProject")} key={index}>
                <NavLink className={styles.link} to={`/products/${slide.title}`}>
                  <img className={styles.image} src={require(`../../assets/images/products/${slide.image}`)} alt=""/>
                  <div className={styles.title}>{slide.title}</div>
                </NavLink>
              </SwiperSlide>
            ))
          }
        </Swiper>
      </div>
    </div>
  )
}
