import styles from './VideoPopup.module.css';

export const VideoPopup = ({data, handleClick}) => {
  return (
    <div className={styles.root} onClick={handleClick}>
      <div className={styles.container} onClick={(e) => e.stopPropagation()}>
        <video playsInline={true} controls={false}>
          <source src={data.videoUrl} type="video/mp4"/>
        </video>
        <div className={styles.close} onClick={handleClick}>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M18 1.81286L16.1871 0L9 7.18714L1.81286 0L0 1.81286L7.18714 9L0 16.1871L1.81286 18L9 10.8129L16.1871 18L18 16.1871L10.8129 9L18 1.81286Z"
              fill="white"/>
          </svg>
        </div>
      </div>
    </div>
  )
}
