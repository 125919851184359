import styles from './Services.module.css';
import {Title} from "../Title";
import {SliderArrow} from "../SliderArrow";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore from "swiper";
import {Navigation} from "swiper/modules";
import {useRef} from "react";
import clsx from "clsx";
import {useTranslation} from "react-i18next";

SwiperCore.use([Navigation]);

export const Services = ({handleClick}) => {
  const {t} = useTranslation()
  const swiperRef = useRef(null);

  const goToNextSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goToPrevSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  return (
    <div className={styles.root}>
      <Title title={t('services.title')} hasRectangle={true} className={styles.title}/>
      <div className={clsx(styles.container, "kfn_anim", "k-fadeUp")}>
        <Swiper
          ref={swiperRef}
          className={styles.slider}
          breakpoints={{
            320: {
              slidesPerView: 1.5,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 1.9,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 2.9,
              spaceBetween: 40,
            },
            1200: {
              slidesPerView: 2.9,
              spaceBetween: 40
            }
          }}
          navigation={{prevEl: '.sliderPrevService', nextEl: '.sliderNextService'}}
        >
          {
            t("services.slides").map((item, index) => (
              <SwiperSlide key={index} onClick={() => handleClick(item)}>
                <div className={styles.image}>
                  <img src={require(`../../assets/images/services/${item.image}`)} alt=""/>
                  <div className={styles.gradient}></div>
                  <svg width="30" height="38" viewBox="0 0 30 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_b_492_254)">
                      <path d="M0 0V38L30 19L0 0Z" fill="#CCCCCC" fillOpacity="0.4"/>
                    </g>
                    <defs>
                      <filter id="filter0_b_492_254" x="-4" y="-4" width="38" height="46" filterUnits="userSpaceOnUse"
                              colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2"/>
                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_492_254"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_492_254" result="shape"/>
                      </filter>
                    </defs>
                  </svg>
                  <div className={styles.duration}>{item.duration}</div>
                </div>
                <div className={styles.sliderTitle}>{item.title}</div>
                <div className={styles.sliderText}>{item.text}</div>
              </SwiperSlide>
            ))
          }
        </Swiper>
        <div className={clsx(styles.sliderArrow, styles.sliderArrowLeft)}>
          <SliderArrow handleClick={goToPrevSlide} className={clsx(styles.sliderPrevService, 'sliderPrevService')}/>
        </div>
        <div className={clsx(styles.sliderArrow, styles.sliderArrowRight)}>
          <SliderArrow handleClick={goToNextSlide} className={clsx(styles.sliderNextService, 'sliderNextService')}
                       direction="right"/>
        </div>
      </div>
      <div className={styles.stick}></div>
    </div>
  )
}
