import clsx from "clsx";

import styles from './SliderArrow.module.css';

export const SliderArrow = ({direction = 'left', handleClick, className}) => {
  return (
    <div onClick={handleClick} className={clsx(className, styles.arrow, styles[direction])}>
      <svg width="42" height="30" viewBox="0 0 42 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.0538 30V18H41.9097L42 11.97H15.0538V0L0 15L15.0538 30Z" fill="white" fillOpacity="0.05"/>
      </svg>
    </div>
  )
}
