import {Logo} from "../Logo";
import styles from './Header.module.css';
import {useCallback, useEffect, useState} from "react";
import {NavLink, useLocation} from "react-router-dom";
import clsx from "clsx";
import {useTranslation} from 'react-i18next';
import {BurgerMenuButton} from "../BurgerMenuButton";
import {LangDropdown} from "../LangDropdown";
import {BurgerMenu} from "../BurgerMenu";

export const Header = () => {
  const {t} = useTranslation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [burgerMenuOpened, setBurgerMenu] = useState(false);
  let location = useLocation()

  const handleScroll = () => {
    setIsHovered(false)
    setIsScrolled(document.documentElement.scrollTop !== 0)
  }

  const handleMenu = () => {
    setBurgerMenu(true)
  }

  const handleHover = () => {
    setIsHovered(true)
  }

  useEffect(
    () => {
      window.scrollTo(0, 0);
      setBurgerMenu(false)
      window.addEventListener('scroll', handleScroll)

      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    },
    [location]
  )

  const offset = (el) => {
    const rect = el.getBoundingClientRect(),
      scrollLeft = window.scrollX || document.documentElement.scrollLeft,
      scrollTop = window.scrollY || document.documentElement.scrollTop;
    return {
      top: rect.top + scrollTop,
      left: rect.left + scrollLeft
    }
  }

  const kfn_anim = useCallback(() => {
    const elements = document.querySelectorAll('.kfn_anim');

    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].classList.contains("active-kfn")) {
        const elementData = elements[i].dataset.kfn;
        if (window.scrollY > offset(elements[i]).top - window.innerHeight) {
          elements[i].classList.add('active-kfn')
          if (elementData !== undefined) {
            elements[i].classList.add(elementData)
          }
        }
      }
    }
  }, [])

  useEffect(
    () => {
      setTimeout(() => {
        kfn_anim()
        window.addEventListener("scroll", kfn_anim)
        window.addEventListener("resize", kfn_anim)
      }, 500)
      window.scrollTo(0, 0);

      return () => {
        window.removeEventListener("scroll", kfn_anim)
        window.removeEventListener("resize", kfn_anim)
      }
    },
    [location, kfn_anim]
  )

  return (
    <div>
      <header className={clsx(styles.header, {
        [styles.scrolled]: isScrolled,
      })}>
        <Logo/>
        <div className={styles.container}>
          <div className={clsx(styles.menuLangs, {[styles.hovered]: isHovered})}>
            <div className={styles.menuItems}>
              {
                (t('menuItems') || []).map(item => (
                  <NavLink to={item.path} key={item.name}>{item.name}</NavLink>
                ))
              }
            </div>
            <LangDropdown/>
          </div>
          <BurgerMenuButton
            className={clsx(
              styles.burgerMenu,
              {
                [styles.hovered]: isHovered,
                [styles.burgerOpened]: burgerMenuOpened
              }
            )}
            handleClick={() => handleMenu()} isOpen={true}
            handleHover={() => handleHover()}/>
        </div>
      </header>
      {burgerMenuOpened && <BurgerMenu handleClick={() => setBurgerMenu(false)}/>}
    </div>
  )
}
