import styles from './Footer.module.css'
import {Logo} from "../Logo";
import {Title} from "../Title";
import {Button} from "../Button";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import {NavLink} from "react-router-dom";

export const Footer = () => {
  const {t} = useTranslation();

  return <footer>
    <div className={clsx(styles.content, "kfn_anim", "k-fadeIn")}>
      <Logo clasName={styles.logo} isFooter/>
      <div className={styles.links}>
        <Title title={t("footer.company")}/>
        <ul className={styles.linksList}>
          {
            t("menuItems").map((item, index) => (
              <NavLink to={item.path} key={index}>
                {item.name}
              </NavLink>
            ))
          }
        </ul>
      </div>
      <div className={styles.addresses}>
        <Title title={t("footer.findUs")}/>
        <div className={styles.address}>{t("footer.email")} <a
          href={t("footer.emailAddress")}>{t("footer.emailAddress")}</a></div>
        <div className={styles.address}>{t("footer.office")} <a
          href={t("footer.map")}>{t("footer.officeAddress")}</a></div>
        <a href={`tel:${t("footer.phone")}`}>
          <Button className={styles.button}>
            {t("footer.directCall")}
          </Button>
        </a>
      </div>
      <div className={styles.icons}>
        <Title title={t("footer.followUs")}/>
        <ul className={styles.iconsList}>
          <a href={t("contact.facebookLink")} target="_blank" rel="noreferrer">
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.5 0C5.62499 0 0 5.63504 0 12.5753C0 18.8504 4.57499 24.0587 10.55 25V16.2149H7.37499V12.5753H10.55V9.80171C10.55 6.65161 12.4125 4.91968 15.275 4.91968C16.6375 4.91968 18.0625 5.15813 18.0625 5.15813V8.25803H16.4875C14.9375 8.25803 14.45 9.2244 14.45 10.2159V12.5753H17.925L17.3625 16.2149H14.45V25C17.3955 24.5329 20.0777 23.024 22.0124 20.7456C23.947 18.4671 25.0067 15.5693 25 12.5753C25 5.63504 19.375 0 12.5 0Z"
                fill="#CCCCCC"/>
            </svg>
          </a>
          <a href={t("contact.instagramLink")} target="_blank" rel="noreferrer">
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.25 0H17.75C21.75 0 25 3.25 25 7.25V17.75C25 19.6728 24.2362 21.5169 22.8765 22.8765C21.5169 24.2362 19.6728 25 17.75 25H7.25C3.25 25 0 21.75 0 17.75V7.25C0 5.32718 0.763837 3.48311 2.12348 2.12348C3.48311 0.763837 5.32718 0 7.25 0ZM7 2.5C5.80653 2.5 4.66193 2.97411 3.81802 3.81802C2.97411 4.66193 2.5 5.80653 2.5 7V18C2.5 20.4875 4.5125 22.5 7 22.5H18C19.1935 22.5 20.3381 22.0259 21.182 21.182C22.0259 20.3381 22.5 19.1935 22.5 18V7C22.5 4.5125 20.4875 2.5 18 2.5H7ZM19.0625 4.375C19.4769 4.375 19.8743 4.53962 20.1674 4.83265C20.4604 5.12567 20.625 5.5231 20.625 5.9375C20.625 6.3519 20.4604 6.74933 20.1674 7.04235C19.8743 7.33538 19.4769 7.5 19.0625 7.5C18.6481 7.5 18.2507 7.33538 17.9576 7.04235C17.6646 6.74933 17.5 6.3519 17.5 5.9375C17.5 5.5231 17.6646 5.12567 17.9576 4.83265C18.2507 4.53962 18.6481 4.375 19.0625 4.375ZM12.5 6.25C14.1576 6.25 15.7473 6.90848 16.9194 8.08058C18.0915 9.25268 18.75 10.8424 18.75 12.5C18.75 14.1576 18.0915 15.7473 16.9194 16.9194C15.7473 18.0915 14.1576 18.75 12.5 18.75C10.8424 18.75 9.25268 18.0915 8.08058 16.9194C6.90848 15.7473 6.25 14.1576 6.25 12.5C6.25 10.8424 6.90848 9.25268 8.08058 8.08058C9.25268 6.90848 10.8424 6.25 12.5 6.25ZM12.5 8.75C11.5054 8.75 10.5516 9.14509 9.84835 9.84835C9.14509 10.5516 8.75 11.5054 8.75 12.5C8.75 13.4946 9.14509 14.4484 9.84835 15.1517C10.5516 15.8549 11.5054 16.25 12.5 16.25C13.4946 16.25 14.4484 15.8549 15.1517 15.1517C15.8549 14.4484 16.25 13.4946 16.25 12.5C16.25 11.5054 15.8549 10.5516 15.1517 9.84835C14.4484 9.14509 13.4946 8.75 12.5 8.75Z"
                fill="#CCCCCC"/>
            </svg>
          </a>
          <a href={t("contact.linkedinLink")} target="_blank" rel="noreferrer">
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M22.2222 0C22.9589 0 23.6655 0.292658 24.1864 0.813592C24.7073 1.33453 25 2.04107 25 2.77778V22.2222C25 22.9589 24.7073 23.6655 24.1864 24.1864C23.6655 24.7073 22.9589 25 22.2222 25H2.77778C2.04107 25 1.33453 24.7073 0.813592 24.1864C0.292658 23.6655 0 22.9589 0 22.2222V2.77778C0 2.04107 0.292658 1.33453 0.813592 0.813592C1.33453 0.292658 2.04107 0 2.77778 0H22.2222ZM21.5278 21.5278V14.1667C21.5278 12.9658 21.0507 11.8142 20.2016 10.965C19.3525 10.1159 18.2008 9.63889 17 9.63889C15.8194 9.63889 14.4444 10.3611 13.7778 11.4444V9.90278H9.90278V21.5278H13.7778V14.6806C13.7778 13.6111 14.6389 12.7361 15.7083 12.7361C16.224 12.7361 16.7186 12.941 17.0833 13.3056C17.4479 13.6703 17.6528 14.1649 17.6528 14.6806V21.5278H21.5278ZM5.38889 7.72222C6.00773 7.72222 6.60122 7.47639 7.03881 7.03881C7.47639 6.60122 7.72222 6.00773 7.72222 5.38889C7.72222 4.09722 6.68056 3.04167 5.38889 3.04167C4.76637 3.04167 4.16934 3.28896 3.72915 3.72915C3.28896 4.16934 3.04167 4.76637 3.04167 5.38889C3.04167 6.68056 4.09722 7.72222 5.38889 7.72222ZM7.31944 21.5278V9.90278H3.47222V21.5278H7.31944Z"
                fill="#CCCCCC"/>
            </svg>

          </a>
        </ul>
      </div>
    </div>
    <div className={styles.copyright}>
      <span>©{new Date().getFullYear()} Aiko</span>
      <div className={styles.terms}>
        <NavLink to={"/terms"}>
          {t("footer.privacy")}
        </NavLink>
        <NavLink to={"/terms"}>
          {t("footer.terms")}
        </NavLink>
      </div>
      <div></div>
    </div>
  </footer>
}
