import React from 'react';
import {Button} from "../Button";

import styles from './Chip.module.css'

export const Chip = ({label}) => {
    return (
        <Button variant='secondary' className={styles.chip}>
            {label}
        </Button>
    );
};

