import React, {useRef} from 'react';
import {SliderArrow} from "../SliderArrow";
import {Swiper, SwiperSlide} from "swiper/react";

import styles from './ChipScroller.module.css'
import {Chip} from "../Chip";

export const ChipScroller = () => {
    const swiperRef = useRef(null);

    const goToNextSlide = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const goToPrevSlide = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    return (
        <div className={styles.sliderContainer}>
            <SliderArrow handleClick={goToPrevSlide} className={styles.sliderPrev}/>
            <Swiper
                ref={swiperRef}
                className={styles.slider}
                slidesPerView="auto"
                spaceBetween={15}
                navigation={{prevEl: '.sliderPrev', nextEl: '.sliderNext'}}
            >
                {
                    [1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
                        <SwiperSlide className={styles.slideItem} key={item}>
                                <Chip label='Tile Cutting'/>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
            <SliderArrow handleClick={goToNextSlide} className={styles.sliderNext} direction="right"/>
        </div>
    );
};

