export default {
  "menuItems": [
    {
      "name": "Home",
      "path": "/"
    },
    {
      "name": "Services",
      "path": "/services"
    },
    {
      "name": "Projects",
      "path": "/projects"
    },
    // {
    //   "name": "Companies",
    //   "path": "/companies"
    // },
    {
      "name": "Products",
      "path": "/products"
    },
    // {
    //   "name": "Gallery",
    //   "path": "/gallery"
    // },
    {
      "name": "Contact Us",
      "path": "/contact"
    }
  ],
  "mainSlider": [
    {
      "title": "BRAND STORY",
      "text": "Aiko is a revered name in the realm of furniturecraftsmanship, celebrated for its unwaveringdedication to excellence and meticulousattention to detail. For over a decade, Aiko hasbeen a cornerstone of the furniture industry inGeorgia, enriching homes, offices, and hotelswith its exquisite creations.",
      "image": "banner.jpg"
    },
    {
      "title": "BRAND STORY 2",
      "text": "Aiko is a revered name in the realm of furniturecraftsmanship, celebrated for its unwaveringdedication to excellence and meticulousattention to detail. For over a decade, Aiko hasbeen a cornerstone of the furniture industry inGeorgia, enriching homes, offices, and hotelswith its exquisite creations.",
      "image": "banner.jpg"
    },
    {
      "title": "BRAND STORY 3",
      "text": "Aiko is a revered name in the realm of furniturecraftsmanship, celebrated for its unwaveringdedication to excellence and meticulousattention to detail. For over a decade, Aiko hasbeen a cornerstone of the furniture industry inGeorgia, enriching homes, offices, and hotelswith its exquisite creations.",
      "image": "banner.jpg"
    }
  ],
  "trustedBy": {
    "title": "TRUSTED BY",
    "collaborations": "COLLABORATIONS",
    "slides": [
      {
        "image": "Company2.png",
        "url": "bog",
        "title": "TBC BANK",
        "description": "TBC Bank, founded in 1992, is Georgia's largest bank by assets, renowned for its diverse financial services. Specializing in retail and corporate banking, it offers loans, deposits, payment processing, and wealth management solutions. TBC Bank is known for innovation in digital banking and has garnered recognition for customer service and financial performance.",
        "mainImage": "tbc.jpg",
        "innerImages": [
          {
            "image": "tbc.jpg",
            "text": "TBC Office at Vazha Pshavela Str."
          },
          {
            "image": "tbc.jpg",
            "text": "TBC Office at Vazha Pshavela Str."
          }
        ]
      },
      {
        "image": "Company2.png",
        "url": "bog2",
        "title": "TBC BANK",
        "description": "TBC Bank, founded in 1992, is Georgia's largest bank by assets, renowned for its diverse financial services. Specializing in retail and corporate banking, it offers loans, deposits, payment processing, and wealth management solutions. TBC Bank is known for innovation in digital banking and has garnered recognition for customer service and financial performance.",
        "mainImage": "tbc.jpg",
        "innerImages": [
          {
            "image": "tbc.jpg",
            "text": "/home/luka/Desktop/aiko/src/assets/images/companies/tbc.jpg"
          }
        ]
      },
      {
        "image": "Company2.png",
        "url": "bog3",
        "title": "TBC BANK",
        "description": "TBC Bank, founded in 1992, is Georgia's largest bank by assets, renowned for its diverse financial services. Specializing in retail and corporate banking, it offers loans, deposits, payment processing, and wealth management solutions. TBC Bank is known for innovation in digital banking and has garnered recognition for customer service and financial performance.",
        "mainImage": "tbc.jpg",
        "innerImages": [
          {
            "image": "tbc.jpg",
            "text": "/home/luka/Desktop/aiko/src/assets/images/companies/tbc.jpg"
          }
        ]
      },
      {
        "image": "Company2.png",
        "url": "bog4",
        "title": "TBC BANK",
        "description": "TBC Bank, founded in 1992, is Georgia's largest bank by assets, renowned for its diverse financial services. Specializing in retail and corporate banking, it offers loans, deposits, payment processing, and wealth management solutions. TBC Bank is known for innovation in digital banking and has garnered recognition for customer service and financial performance.",
        "mainImage": "tbc.jpg",
        "innerImages": [
          {
            "image": "tbc.jpg",
            "text": "/home/luka/Desktop/aiko/src/assets/images/companies/tbc.jpg"
          }
        ]
      },
      {
        "image": "Company2.png",
        "url": "bog5",
        "title": "TBC BANK",
        "description": "TBC Bank, founded in 1992, is Georgia's largest bank by assets, renowned for its diverse financial services. Specializing in retail and corporate banking, it offers loans, deposits, payment processing, and wealth management solutions. TBC Bank is known for innovation in digital banking and has garnered recognition for customer service and financial performance.",
        "mainImage": "tbc.jpg",
        "innerImages": [
          {
            "image": "tbc.jpg",
            "text": "/home/luka/Desktop/aiko/src/assets/images/companies/tbc.jpg"
          }
        ]
      },
      {
        "image": "Company2.png",
        "url": "bog6",
        "title": "TBC BANK",
        "description": "TBC Bank, founded in 1992, is Georgia's largest bank by assets, renowned for its diverse financial services. Specializing in retail and corporate banking, it offers loans, deposits, payment processing, and wealth management solutions. TBC Bank is known for innovation in digital banking and has garnered recognition for customer service and financial performance.",
        "mainImage": "tbc.jpg",
        "innerImages": [
          {
            "image": "tbc.jpg",
            "text": "/home/luka/Desktop/aiko/src/assets/images/companies/tbc.jpg"
          }
        ]
      },
      {
        "image": "Company2.png",
        "url": "bog7",
        "title": "TBC BANK",
        "description": "TBC Bank, founded in 1992, is Georgia's largest bank by assets, renowned for its diverse financial services. Specializing in retail and corporate banking, it offers loans, deposits, payment processing, and wealth management solutions. TBC Bank is known for innovation in digital banking and has garnered recognition for customer service and financial performance.",
        "mainImage": "tbc.jpg",
        "innerImages": [
          {
            "image": "tbc.jpg",
            "text": "/home/luka/Desktop/aiko/src/assets/images/companies/tbc.jpg"
          }
        ]
      },
      {
        "image": "Company2.png",
        "url": "bog8",
        "title": "TBC BANK",
        "description": "TBC Bank, founded in 1992, is Georgia's largest bank by assets, renowned for its diverse financial services. Specializing in retail and corporate banking, it offers loans, deposits, payment processing, and wealth management solutions. TBC Bank is known for innovation in digital banking and has garnered recognition for customer service and financial performance.",
        "mainImage": "tbc.jpg",
        "innerImages": [
          {
            "image": "tbc.jpg",
            "text": "/home/luka/Desktop/aiko/src/assets/images/companies/tbc.jpg"
          }
        ]
      },
    ]
  },
  "services": {
    "title": "SERVICES",
    "slides": [
      {
        "title": "Tile Cutting",
        "text": "Tile cutting involves using tools like tile cutters, saws, nippers, or grinders to cut ceramic or porcelain tiles to size or shape for installation in various settings.",
        "videoUrl": "https://www.youtube.com/watch?v=MvF2mx3tlhE",
        "duration": "00:15",
        "image": "banner.jpg"
      },
      {
        "title": "Tile Cutting 2",
        "text": "Tile cutting involves using tools like tile cutters, saws, nippers, or grinders to cut ceramic or porcelain tiles to size or shape for installation in various settings.",
        "videoUrl": "url",
        "duration": "00:15",
        "image": "banner.jpg"
      },
      {
        "title": "Tile Cutting 3",
        "text": "Tile cutting involves using tools like tile cutters, saws, nippers, or grinders to cut ceramic or porcelain tiles to size or shape for installation in various settings.",
        "videoUrl": "url",
        "duration": "00:15",
        "image": "banner.jpg"
      },
      {
        "title": "Tile Cutting 4",
        "text": "Tile cutting involves using tools like tile cutters, saws, nippers, or grinders to cut ceramic or porcelain tiles to size or shape for installation in various settings.",
        "videoUrl": "url",
        "duration": "00:15",
        "image": "banner.jpg"
      },
      {
        "title": "Tile Cutting 5",
        "text": "Tile cutting involves using tools like tile cutters, saws, nippers, or grinders to cut ceramic or porcelain tiles to size or shape for installation in various settings.",
        "videoUrl": "url",
        "duration": "00:15",
        "image": "banner.jpg"
      },
    ]
  },
  "contact": {
    "title": "CONTACT",
    "YourProjectIs": "Your Project is:",
    "Individual": "Individual",
    "Corporate": "Corporate",
    "yourName": "Your Name",
    "YourEmailAddress": "Your Email Address",
    "ProjectDetails": "Project Details",
    "Iagreetohavemypersonalinformationusedtorespondtomyrequest": "I agree to have my personal information used to respond to my request.",
    "IwouldliketoreceiveinformationandupdatesfromAikothatcouldbeofinteresttome": "I would like to receive information and updates from Aiko that could be of interest to me.",
    "SendRequest": "Send Request",
    "facebookLink": "facebook.com",
    "instagramLink": "instagram.com",
    "linkedinLink": "linkedin.com"
  },
  "projects": {
    "title": "PROJECTS",
    "otherProjects": "OTHER PROJECTS",
    "projectsMainPageSlider": [
      {
        "image": "banner.jpg",
        "title": "Office Shelves With Doors",
        "size": "20"
      },
      {
        "image": "gallery.jpg",
        "title": "Office Shelves With Doors",
        "size": "80"
      },
      {
        "image": "banner.jpg",
        "title": "Office Shelves With Doors",
        "size": "30"
      },
      {
        "image": "banner.jpg",
        "title": "Office Shelves With Doors",
        "size": "90"
      },
      {
        "image": "banner.jpg",
        "title": "Office Shelves With Doors",
        "size": "80"
      }
    ],
    "projects": [
      {
        "title": "AIKO x TBC",
        "company": "TBC",
        "descriptions": [
          {
            "title": "Project:",
            "value": "Corporate"
          },
          {
            "title": "Project:",
            "value": "Corporate"
          },
          {
            "title": "Project:",
            "value": "Corporate"
          },
        ],
        "mainPageImage": "banner.jpg",
        "innerPageImages": ["banner.jpg", "banner.jpg", "banner.jpg", "banner.jpg", "banner.jpg", "banner.jpg"],
        "innerText": {
          "title": "ABOUT PROJECT",
          "text": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam nemo odit velit! Eos quia ut vitae voluptatum. Aperiam atque cumque, facere fuga maxime nemo nobis perferendis rem rerum saepe, veritatis."
        },
        "companyImage": "Logo.png"
      },
      {
        "title": "AIKO x TBC 2",
        "company": "TBC",
        "descriptions": [
          {
            "title": "Project:",
            "value": "Corporate"
          },
          {
            "title": "Project:",
            "value": "Corporate"
          },
          {
            "title": "Project:",
            "value": "Corporate"
          }
        ],
        "mainPageImage": "banner.jpg",
        "innerPageImages": ["banner.jpg", "banner.jpg", "banner.jpg", "banner.jpg", "banner.jpg", "banner.jpg"],
        "companyImage": "Logo.png"
      },
      {
        "title": "AIKO x TBC 3",
        "company": "TBC",
        "descriptions": [
          {
            "title": "Project:",
            "value": "Corporate"
          },
          {
            "title": "Project:",
            "value": "Corporate"
          },
          {
            "title": "Project:",
            "value": "Corporate"
          }
        ],
        "mainPageImage": "banner.jpg",
        "innerPageImages": ["banner.jpg", "banner.jpg", "banner.jpg", "banner.jpg", "banner.jpg", "banner.jpg"],
        "innerText": {
          "title": "ABOUT PROJECT",
          "text": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam nemo odit velit! Eos quia ut vitae voluptatum. Aperiam atque cumque, facere fuga maxime nemo nobis perferendis rem rerum saepe, veritatis."
        },
        "companyImage": "Logo.png"
      },
      {
        "title": "AIKO x TBC 4",
        "company": "TBC",
        "descriptions": [
          {
            "title": "Project:",
            "value": "Corporate"
          },
          {
            "title": "Project:",
            "value": "Corporate"
          },
          {
            "title": "Project:",
            "value": "Corporate"
          }
        ],
        "mainPageImage": "banner.jpg",
        "innerPageImages": ["banner.jpg", "banner.jpg", "banner.jpg", "banner.jpg", "banner.jpg", "banner.jpg"],
        "innerText": {
          "title": "ABOUT PROJECT",
          "text": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam nemo odit velit! Eos quia ut vitae voluptatum. Aperiam atque cumque, facere fuga maxime nemo nobis perferendis rem rerum saepe, veritatis."
        },
        "companyImage": "Logo.png"
      },
      {
        "title": "AIKO x TBC 5",
        "company": "TBC",
        "descriptions": [
          {
            "title": "Project:",
            "value": "Corporate"
          },
          {
            "title": "Project:",
            "value": "Corporate"
          },
          {
            "title": "Project:",
            "value": "Corporate"
          }
        ],
        "mainPageImage": "banner.jpg",
        "innerPageImages": ["banner.jpg", "banner.jpg", "banner.jpg", "banner.jpg", "banner.jpg", "banner.jpg"],
        "innerText": {
          "title": "ABOUT PROJECT",
          "text": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam nemo odit velit! Eos quia ut vitae voluptatum. Aperiam atque cumque, facere fuga maxime nemo nobis perferendis rem rerum saepe, veritatis."
        },
        "companyImage": "Logo.png"
      },
      {
        "company": "TBC",
        "title": "AIKO x TBC 6",
        "descriptions": [
          {
            "title": "Project:",
            "value": "Corporate"
          },
          {
            "title": "Project:",
            "value": "Corporate"
          },
          {
            "title": "Project:",
            "value": "Corporate"
          }
        ],
        "mainPageImage": "banner.jpg",
        "innerPageImages": ["banner.jpg", "banner.jpg", "banner.jpg", "banner.jpg", "banner.jpg", "banner.jpg"],
        "innerText": {
          "title": "ABOUT PROJECT",
          "text": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam nemo odit velit! Eos quia ut vitae voluptatum. Aperiam atque cumque, facere fuga maxime nemo nobis perferendis rem rerum saepe, veritatis."
        },
        "companyImage": "Logo.png"
      },
      {
        "title": "AIKO x TBC 7",
        "company": "TBC",
        "descriptions": [
          {
            "title": "Project:",
            "value": "Corporate"
          },
          {
            "title": "Project:",
            "value": "Corporate"
          },
          {
            "title": "Project:",
            "value": "Corporate"
          }
        ],
        "mainPageImage": "banner.jpg",
        "innerPageImages": ["banner.jpg", "banner.jpg", "banner.jpg", "banner.jpg", "banner.jpg", "banner.jpg"],
        "innerText": {
          "title": "ABOUT PROJECT",
          "text": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam nemo odit velit! Eos quia ut vitae voluptatum. Aperiam atque cumque, facere fuga maxime nemo nobis perferendis rem rerum saepe, veritatis."
        },
        "companyImage": "Logo.png"
      },
      {
        "title": "AIKO x TBC 8",
        "company": "TBC",
        "descriptions": [
          {
            "title": "Project:",
            "value": "Corporate"
          },
          {
            "title": "Project:",
            "value": "Corporate"
          },
          {
            "title": "Project:",
            "value": "Corporate"
          }
        ],
        "mainPageImage": "banner.jpg",
        "innerPageImages": ["banner.jpg", "banner.jpg", "banner.jpg", "banner.jpg", "banner.jpg", "banner.jpg"],
        "innerText": {
          "title": "ABOUT PROJECT",
          "text": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam nemo odit velit! Eos quia ut vitae voluptatum. Aperiam atque cumque, facere fuga maxime nemo nobis perferendis rem rerum saepe, veritatis."
        },
        "companyImage": "Logo.png"
      }
    ]
  },
  "gallery": {
    "title": "GALLERY",
    "viewAll": "View All",
    "images": [
      {
        "desktop": "banner.jpg",
        "mobile": "banner.jpg",
        "title": "Kitchen Design",
        "text": "Individual Project"
      },
      {
        "desktop": "banner.jpg",
        "mobile": "gallery.jpg",
        "title": "Kitchen Design",
        "text": "Individual Project"
      },
      {
        "desktop": "banner.jpg",
        "mobile": "banner.jpg",
        "title": "Kitchen Design",
        "text": "Individual Project"
      },
      {
        "desktop": "gallery.jpg",
        "mobile": "gallery.jpg",
        "title": "Kitchen Design",
        "text": "Individual Project"
      },
      {
        "desktop": "banner.jpg",
        "mobile": "gallery.jpg",
        "title": "Kitchen Design",
        "text": "Individual Project"
      },
      {
        "desktop": "banner.jpg",
        "mobile": "banner.jpg",
        "title": "Kitchen Design",
        "text": "Individual Project"
      },
      {
        "desktop": "gallery.jpg",
        "mobile": "banner.jpg",
        "title": "Kitchen Design",
        "text": "Individual Project"
      },
      {
        "desktop": "gallery.jpg",
        "mobile": "gallery.jpg",
        "title": "Kitchen Design",
        "text": "Individual Project"
      },
      {
        "desktop": "banner.jpg",
        "mobile": "banner.jpg",
        "title": "Kitchen Design",
        "text": "Individual Project"
      },
      {
        "desktop": "gallery.jpg",
        "mobile": "gallery.jpg",
        "title": "Kitchen Design",
        "text": "Individual Project"
      },
      {
        "desktop": "banner.jpg",
        "mobile": "banner.jpg",
        "title": "Kitchen Design",
        "text": "Individual Project"
      },
      {
        "desktop": "banner.jpg",
        "mobile": "banner.jpg",
        "title": "Kitchen Design",
        "text": "Individual Project"
      }
    ]
  },
  "products": {
    "title": "PRODUCTS",
    "similarTitle": "SIMILAR PRODUCTS",
    "items": [
      {
        "image": "banner.jpg",
        "image_mobile": "banner.jpg",
        "title": "Office Shelves With Doors",
        "inner_page": {
          "images": [
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
          ],
          "description": [
            {
              "title": "Manufacturer Of Materials:",
              "text": "Germany,Turkey"
            },
            {
              "title": "Manufacturer Of Materials:",
              "text": "Germany,Turkey"
            }
          ],
          "information": "For detailed information:",
          "call": "Call Now",
          "phone": "532342432"
        }
      },
      {
        "image": "banner.jpg",
        "image_mobile": "banner.jpg",
        "title": "Office Shelves With Doors 2",
        "inner_page": {
          "images": [
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
          ],
          "description": [
            {
              "title": "Manufacturer Of Materials:",
              "text": "Germany,Turkey"
            }
          ],
          "information": "For detailed information:",
          "call": "Call Now",
          "phone": "532342432"
        }
      },
      {
        "image": "banner.jpg",
        "image_mobile": "banner.jpg",
        "title": "Office Shelves With Doors 3",
        "inner_page": {
          "images": [
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
          ],
          "description": [
            {
              "title": "Manufacturer Of Materials:",
              "text": "Germany,Turkey"
            }
          ],
          "information": "For detailed information:",
          "call": "Call Now",
          "phone": "532342432"
        }
      },
      {
        "image": "banner.jpg",
        "image_mobile": "banner.jpg",
        "title": "Office Shelves With Doors 4",
        "inner_page": {
          "images": [
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
          ],
          "description": [
            {
              "title": "Manufacturer Of Materials:",
              "text": "Germany,Turkey"
            }
          ],
          "information": "For detailed information:",
          "call": "Call Now",
          "phone": "532342432"
        }
      },
      {
        "image": "banner.jpg",
        "image_mobile": "banner.jpg",
        "title": "Office Shelves With Doors 5",
        "inner_page": {
          "images": [
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
          ],
          "description": [
            {
              "title": "Manufacturer Of Materials:",
              "text": "Germany,Turkey"
            }
          ],
          "information": "For detailed information:",
          "call": "Call Now",
          "phone": "532342432"
        }
      },
      {
        "image": "banner.jpg",
        "image_mobile": "banner.jpg",
        "title": "Office Shelves With Doors",
        "inner_page": {
          "images": [
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
          ],
          "description": [
            {
              "title": "Manufacturer Of Materials:",
              "text": "Germany,Turkey"
            }
          ],
          "information": "For detailed information:",
          "call": "Call Now",
          "phone": "532342432"
        }
      },
      {
        "image": "banner.jpg",
        "image_mobile": "banner.jpg",
        "title": "Office Shelves With Doors",
        "inner_page": {
          "images": [
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
          ],
          "description": [
            {
              "title": "Manufacturer Of Materials:",
              "text": "Germany,Turkey"
            }
          ],
          "information": "For detailed information:",
          "call": "Call Now",
          "phone": "532342432"
        }
      },
      {
        "image": "banner.jpg",
        "image_mobile": "banner.jpg",
        "title": "Office Shelves With Doors",
        "inner_page": {
          "images": [
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
          ],
          "description": [
            {
              "title": "Manufacturer Of Materials:",
              "text": "Germany,Turkey"
            }
          ],
          "information": "For detailed information:",
          "call": "Call Now",
          "phone": "532342432"
        }
      },
      {
        "image": "banner.jpg",
        "image_mobile": "banner.jpg",
        "title": "Office Shelves With Doors",
        "inner_page": {
          "images": [
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
          ],
          "description": [
            {
              "title": "Manufacturer Of Materials:",
              "text": "Germany,Turkey"
            }
          ],
          "information": "For detailed information:",
          "call": "Call Now",
          "phone": "532342432"
        }
      },
      {
        "image": "banner.jpg",
        "image_mobile": "banner.jpg",
        "title": "Office Shelves With Doors",
        "inner_page": {
          "images": [
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
          ],
          "description": [
            {
              "title": "Manufacturer Of Materials:",
              "text": "Germany,Turkey"
            }
          ],
          "information": "For detailed information:",
          "call": "Call Now",
          "phone": "532342432"
        }
      },
      {
        "image": "banner.jpg",
        "image_mobile": "banner.jpg",
        "title": "Office Shelves With Doors",
        "inner_page": {
          "images": [
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
          ],
          "description": [
            {
              "title": "Manufacturer Of Materials:",
              "text": "Germany,Turkey"
            }
          ],
          "information": "For detailed information:",
          "call": "Call Now",
          "phone": "532342432"
        }
      },
      {
        "image": "banner.jpg",
        "image_mobile": "banner.jpg",
        "title": "Office Shelves With Doors",
        "inner_page": {
          "images": [
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
          ],
          "description": [
            {
              "title": "Manufacturer Of Materials:",
              "text": "Germany,Turkey"
            }
          ],
          "information": "For detailed information:",
          "call": "Call Now",
          "phone": "532342432"
        }
      },
      {
        "image": "banner.jpg",
        "image_mobile": "banner.jpg",
        "title": "Office Shelves With Doors",
        "inner_page": {
          "images": [
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
            "banner.jpg",
          ],
          "description": [
            {
              "title": "Manufacturer Of Materials:",
              "text": "Germany,Turkey"
            }
          ],
          "information": "For detailed information:",
          "call": "Call Now",
          "phone": "532342432"
        }
      },
    ]
  },
  "footer": {
    "company": "COMPANY",
    "findUs": "FIND US",
    "followUs": "FOLLOW US",
    "email": "Email Address:",
    "emailAddress": "Info@aiko.ge",
    "office": "Office Address:",
    "officeAddress": "Tsereteli Aven.13, Tbilisi, Georgia",
    "map": "https://maps.app.goo.gl/hhnjSrnth3DrXnqE7",
    "directCall": "Direct Call",
    "phone": "323423432",
    "privacy": "Privacy Policy",
    "terms": "Terms and Conditions"
  }
}
