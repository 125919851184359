import styles from './Gallery.module.css';
import {useMedia} from "use-media";
import {GalleryDesktop} from "../GalleryDesktop";
import {useTranslation} from "react-i18next";
import {GalleryMobile} from "../GalleryMobile";
import clsx from "clsx";

export const Gallery = () => {
  const {t} = useTranslation();
  const isWide = useMedia({minWidth: '1024px'});

  const galleryImages = t("gallery.images");

  if (!galleryImages) {
    return null;
  }

  const images = galleryImages.reduce((acc, curr, index) => {
    const chunkIndex = Math.floor(index / 6);
    if (!acc[chunkIndex]) {
      acc[chunkIndex] = [];
    }
    acc[chunkIndex].push(curr);
    return acc;
  }, []);

  return (
    <div className={clsx(styles.root, "kfn_anim", "k-fadeIn")}>
      {isWide ?
        <GalleryDesktop images={images} title={t("gallery.title")} viewAll={t("gallery.viewAll")}/> :
        <GalleryMobile images={images} title={t("gallery.title")} viewAll={t("gallery.viewAll")}/>
      }
      <div className={clsx(styles.border, "kfn_anim", "k-fadeUp")}></div>
    </div>
  )
}
