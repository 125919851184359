import clsx from "clsx";
import {NavLink} from "react-router-dom";

import styles from './ProjectItem.module.css';

export const ProjectItem = ({className, project}) => {
  console.log(project);
  return (
    <div className={clsx(className, styles.project, "kfn_anim", "k-fadeUp")}>
      <div className={styles.head}>
        <div className={styles.image}>
          <NavLink to={`/company/${project?.company}`}>
            <img src={require(`../../assets/images/projects/${project.companyImage}`)} alt=""/>
          </NavLink>
        </div>
        <div className={styles.description}>
          <div className={styles.title}>
            {project.title}
          </div>
          <div className={styles.subDescription}>
            {project.descriptions.map((item, index) => (
              <div className={styles.listItem} key={index}>
                <span>{item.title}</span>
                <span>{item.value}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.video}>
        <NavLink className={styles.link} to={`/projects/${project?.title}`}>
          <img src={require(`../../assets/images/projects/${project.mainPageImage}`)} alt=""/>
          <div className={styles.layer}></div>
          <div className={styles.arrow}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 6.9899V9.0101H12.1212L6.56566 14.5657L8 16L16 8L8 0L6.56566 1.43434L12.1212 6.9899H0Z"
                    fill="#100C08"/>
            </svg>
          </div>
        </NavLink>
      </div>
    </div>
  )
}
