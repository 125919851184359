import React, {useRef} from 'react';

import styles from './ContactForm.module.css';
import {Radio} from "../Radio";
import {Input} from "../Input";
import {Textarea} from "../Textarea";
import {Button} from "../Button";
import {useForm} from "react-hook-form";
import {Checkbox} from "../Checkbox";
import {ChipScroller} from "../ChipScroller";
import {useTranslation} from "react-i18next";
import emailjs from '@emailjs/browser';


export const ContactForm = ({variant}) => {
  const {t} = useTranslation();
  const {register, handleSubmit} = useForm();
  const form = useRef();
  const onSubmit = (e) => {
    console.log(e, form.current)

    emailjs
      .sendForm('service_21cn7bd', 'template_95x0038', form.current, {
        publicKey: 'icsJnTu_FwsJLNN_Z',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <form ref={form} className={styles[variant]} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.formContent}>
        <div className={styles.common}>
          <div>
            <h4 className={styles.radioTitle}>{t('contact.YourProjectIs')}</h4>
            {variant === 'section' ? <div className={styles.radioContainer}>
              <Radio label={t("contact.Individual")} value='individual' id='individual'  {...register('project')}    />
              <Radio label={t("contact.Corporate")} value='corporate' id='corporate' {...register('project')}/>
            </div> : <ChipScroller/>}
          </div>
          <div className={styles.inputContainer}>
            <Input placeholder={t("contact.yourName")}  {...register('name')} />
            <Input placeholder={t("contact.YourEmailAddress")} type='email' {...register('email')} />
          </div>
          <Textarea placeholder={t("contact.ProjectDetails")}
                    className={styles.textarea} {...register('description')} />

        </div>

        {variant === 'section' && <>
          <Checkbox
            label={t('contact.Iagreetohavemypersonalinformationusedtorespondtomyrequest')} {...register('terms')}/>
          <Checkbox
            label={t('contact.IwouldliketoreceiveinformationandupdatesfromAikothatcouldbeofinteresttome')}
            {...register('updates')}/>
        </>}

      </div>

      <Button type='submit'>{t('contact.SendRequest')}</Button>

    </form>
  );
};

