import React from 'react';

import styles from './CompanyItem.module.css';
import clsx from "clsx";

export const CompanyItem = ({text, image}) => {
  return (
    <div className={clsx(styles.root, "kfn_anim", "k-fadeUp")}>
      <div className={styles.imageWrapper}>
        <img className={styles.image} src={require(`../../assets/images/trustedBy/${image}`)} alt=""/>
        <div className={styles.imageOverlay}></div>
      </div>
      <p className={styles.title}>{text}</p>
    </div>
  );
};

