import React from 'react';

import styles from './ProductsTab.module.css';

export const ProductsTab = () => {
    return (
        <div className={styles.root}>

        </div>
    );
};