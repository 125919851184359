import styles from './GalleryDesktop.module.css';
import {Title} from "../Title";
import {SliderArrow} from "../SliderArrow";
import {Button} from "../Button";
import {EffectFade} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";
import clsx from "clsx";
import {useNavigate} from "react-router-dom";

export const GalleryDesktop = ({images, title, viewAll}) => {
  const navigate = useNavigate();
  const handleRoute = () => navigate("/projects");
  
  return (
    <div>
      <div className={styles.head}>
        <Title className={styles.title} rectangleLeft={true} title={title} hasRectangle={true}/>
        <div className={styles.side}>
          <div className={styles.arrows}>
            <SliderArrow className="sliderPrevGallery"/>
            <SliderArrow direction="right" className="sliderNextGallery"/>
          </div>
          <Button onClick={handleRoute} variant="secondary">
            {viewAll}
          </Button>
        </div>
      </div>
      <div className={styles.sliderContainer}>
        <Swiper
          className={clsx(styles.slider, 'gallerySlider')}
          modules={[EffectFade]}
          effect="fade"
          navigation={{prevEl: '.sliderPrevGallery', nextEl: '.sliderNextGallery'}}
        >
          {images.map((slide, index) => (
            <SwiperSlide className={clsx(styles.slide)} key={index}>
              {slide.map((item, index) => (
                <div key={index} className={styles.item}>
                  <img className={styles.image}
                       src={require(`../../assets/images/gallery/${item.desktop}`)}
                       alt=""/>
                  <div className={styles.layer}></div>
                  <div className={styles.text}>
                    <h2>{item.title}</h2>
                    <h3>{item.text}</h3>
                  </div>
                </div>
              ))}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}
