import clsx from "clsx";
import {Rectangle} from "../Rectangle";
import styles from './Title.module.css';

export const Title = ({title, className, hasRectangle, rectangleLeft}) => {
  return (
    <div className={clsx(styles.root, className)}>
      {hasRectangle && <Rectangle className={clsx(styles.rectangle, {[styles.left]: rectangleLeft})}/>}
      <h2 className={clsx(styles.title, "kfn_anim", "k-slideUp")}>{title}</h2>
    </div>
  )
}
