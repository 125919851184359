import styles from './ProjectSlider.module.css';
import './ProjectSlider.css'
import {EffectFade} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";
import {useEffect, useRef, useState} from "react";
import {SliderArrow} from "../SliderArrow";
import clsx from "clsx";

export const ProjectSlider = ({project}) => {
  const mainSlider = useRef(null);
  const secondarySlider = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    if (mainSlider.current && secondarySlider.current) {
      const swiper1 = mainSlider.current.swiper;
      const swiper2 = secondarySlider.current.swiper;

      swiper1.on('slideChange', () => {
        swiper2.slideTo(swiper1.activeIndex);
      });

      swiper2.on('slideChange', () => {
        swiper1.slideTo(swiper2.activeIndex);
      });
    }
  }, []);

  const handleSlide = (index) => {
    secondarySlider.current.swiper.slideTo(index)
    mainSlider.current.swiper.slideTo(index)
  }

  if (!project) {
    return null;
  }

  return (
    <div className={clsx(styles.root, "kfn_anim", "k-fadeIn")}>
      <Swiper
        ref={mainSlider}
        className={styles.mainSlider}
        modules={[EffectFade]}
        onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
        effect="fade"
      >
        {project.innerPageImages.map((slide, index) => (
          <SwiperSlide className={styles.slide} key={index}>
            <img src={require(`../../assets/images/projects/${slide}`)} alt=""/>
            <div className={styles.layer}></div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={styles.footer}>
        <div className={clsx(styles.secondarySliderContent, 'secondarySlider')}>
          <SliderArrow className={clsx(styles.arrow, 'sliderPrevProject')}/>
          <Swiper
            ref={secondarySlider}
            breakpoints={{
              320: {
                slidesPerView: 3.2,
                spaceBetween: 5,
              },
              768: {
                slidesPerView: 3.2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1365: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
              1440: {
                slidesPerView: 5,
                spaceBetween: 20,
              }
            }}
            navigation={{prevEl: '.sliderPrevProject', nextEl: '.sliderNextProject'}}
          >
            {
              project.innerPageImages.map((item, index) => (
                <SwiperSlide key={index} className={styles.secondarySliderItem}
                             onClick={() => handleSlide(index)}>
                  <div>
                    <img src={require(`../../assets/images/projects/${item}`)} alt=""
                         className={clsx({[styles.activeImg]: activeIndex === index})}/>
                  </div>
                </SwiperSlide>
              ))
            }
          </Swiper>
          <SliderArrow className={clsx(styles.arrow, 'sliderNextProject')} direction="right"/>
        </div>
        <div className={styles.description}>
          <div className={styles.title}>{project.title}</div>
          {project.descriptions.map((item, index) => (
            <div className={styles.subDescription} key={index}>
              <span>{item.title}</span>
              <span>{item.value}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
