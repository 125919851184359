import styles from './MainSlider.module.css';
import {Swiper, SwiperSlide} from "swiper/react";
import {EffectFade} from "swiper/modules";
import {Rectangle} from "../Rectangle";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import clsx from "clsx";

export const MainSlider = () => {
  const {t} = useTranslation();
  const [activeIndex, setActiveIndex] = useState();

  return (
    <>
      {
        Boolean(t('mainSlider').length) && <div className={clsx(styles.root, "kfn_anim", "k-fadeIn")}>
          <div className={styles.counter}>
            <div className={styles.activeIndex}>
              {activeIndex}
            </div>
            <div className={styles.stick}>/</div>
            <div className={styles.sum}>{t('mainSlider').length}</div>
          </div>
          <Swiper
            className={styles.slider}
            modules={[EffectFade]}
            onSwiper={(swiper) => setActiveIndex(swiper.activeIndex + 1)}
            onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex + 1)}
            effect="fade"
          >
            {t('mainSlider').map((slide, index) => (
              <SwiperSlide className={styles.slide} key={index}>
                <img className={styles.image} src={require(`../../assets/images/mainSlider/${slide.image}`)} alt=""/>
                <div className={styles.shadow}></div>
                <div className={styles.content}>
                  <Rectangle className={styles.rectangle}/>
                  <h2 className={styles.title}>{slide.title}</h2>
                  <p className={styles.text} dangerouslySetInnerHTML={{__html: slide.text}}></p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      }
    </>
  )
}
