import React from 'react';
import {Breadcrumbs} from "../../components/Breadcrumbs";
import {useParams} from "react-router";

import styles from './Company.module.css'
import {Title} from "../../components";
import {CompanyItem} from "../../components/CompanyItem";
import clsx from "clsx";
import {useTranslation} from "react-i18next";

export const Company = () => {
  const {t} = useTranslation();
  const {id} = useParams()
  const breadcrumbs = [{title: t("projects.title"), to: '/projects'}, {title: id, to: `/company/${id}`}];

  const company = t("trustedBy.slides").find(item => item.url === id);

  if (!company) {
    return null;
  }

  return (
    <div>
      <section className={styles.top}
               style={{backgroundImage: `url(${require(`../../assets/images/trustedBy/${company.mainImage}`)})`}}>
        <Breadcrumbs links={breadcrumbs}/>
        <div className={styles.topContent}>
          <Title className={styles.title} title={company.title}/>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <p className={clsx(styles.description, "kfn_anim", "k-fadeUp")}
             dangerouslySetInnerHTML={{__html: company.description}}></p>
        </div>
      </section>
      <section className={styles.colabs}>
        <div className={clsx(styles.colabsList, "kfn_anim", "k-fadeIn")}>
          {
            company.innerImages &&
            company.innerImages
              .map((colab, index) => <CompanyItem key={index} {...colab}/>)}
        </div>
        <Title className={styles.colabsTitle} title={t("trustedBy.collaborations")}/>
      </section>
    </div>
  );
};

