import './App.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {Home, Layout, Product, Products, Project, Projects, Services} from "./pages";
import {Contact} from "./pages/Contact";
import {Company} from "./pages/Company/Company";

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout/>,
    children: [
      {path: '/', element: <Home/>},
      {path: '/projects', element: <Projects/>},
      {path: '/projects/:id', element: <Project/>},
      {path: '/contact', element: <Contact/>},
      {path: '/services', element: <Services/>},
      {path: '/products', element: <Products/>},
      {path: '/products/:id', element: <Product/>},
      {path: '/company/:id', element: <Company/>},
    ]
  }
])


function App() {
  return <RouterProvider router={router}/>
}

export default App;
