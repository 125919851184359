import {SliderArrow} from "../SliderArrow";
import {Title} from "../Title";
import {Swiper, SwiperSlide} from "swiper/react";
import {Link} from "react-router-dom";
import {Navigation} from "swiper/modules";
import SwiperCore from "swiper";
import styles from './TrustedBy.module.css';
import {Rectangle} from "../Rectangle";
import {useRef} from "react";
import {useTranslation} from "react-i18next";
import clsx from "clsx";

SwiperCore.use([Navigation]);

export const TrustedBy = () => {
  const {t} = useTranslation();
  const swiperRef = useRef(null);

  const goToNextSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goToPrevSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.borderLeft}></div>
      <div className={styles.borderRight}></div>
      <Title hasRectangle={false} title={t('trustedBy.title')} className={styles.title}/>
      <div className={clsx(styles.sliderContainer, "kfn_anim", "k-fadeIn")}>
        <SliderArrow handleClick={goToPrevSlide} className={styles.sliderPrev}/>
        <Swiper
          ref={swiperRef}
          className={styles.slider}
          breakpoints={{
            320: {
              slidesPerView: 4.5,
              spaceBetween: 15,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            1200: {
              slidesPerView: 5,
              spaceBetween: 40
            }
          }}
          navigation={{prevEl: '.sliderPrev', nextEl: '.sliderNext'}}
        >
          {
            t('trustedBy.slides').map((item, index) => (
              <SwiperSlide className={styles.slideItem} key={index}>
                <Link to={`/company/${item.url}`}>
                  <img src={require(`../../assets/images/trustedBy/${item.image}`)} alt=""/>
                </Link>
              </SwiperSlide>
            ))
          }
        </Swiper>
        <SliderArrow handleClick={goToNextSlide} className={styles.sliderNext} direction="right"/>
      </div>
      <Rectangle className={styles.rectangle}/>
    </div>
  )
}
