import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import styles from './LangDropdown.module.css';
import clsx from "clsx";

export const LangDropdown = ({className}) => {
  const {i18n} = useTranslation();
  const [active, setIsActive] = useState(false);
  const [languages, setLanguages] = useState([]);

  const allLanguages = ['en', 'fr'];

  useEffect(() => {
    setLanguages(() => {
      return allLanguages.filter(lang => lang !== i18n.language)
    })
    // eslint-disable-next-line
  }, [i18n.language]);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language).then(() => setIsActive(false));
  };

  return (
    <div className={clsx(className, styles.root, {[styles.active]: active})}>
      <div className={styles.activeLang} onClick={() => setIsActive(!active)}>
        <span>{i18n.language}</span>
        <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.41 0.5L6 5.44879L10.59 0.5L12 2.031L6 8.5L0 2.031L1.41 0.5Z" fill="white" fillOpacity="0.4"/>
        </svg>
      </div>
      <div className={styles.langs}>
        {
          languages.map(lang => (
            <div key={lang} className={styles.lang} onClick={() => changeLanguage(lang)}>
              {lang}
            </div>
          ))
        }
      </div>
    </div>
  );
}
